import 'isomorphic-fetch';
import {config} from 'config'

export const api = {
    user:{
        async create(data) {
            const response = await fetch(config.URL_API + '/usuarios', {
			  method: 'POST',
			  headers: {
			    'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
			  },
			  body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async getAll(query = '') {
            const response = await fetch(config.URL_API + `/usuarios?${query}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/usuarios/${id}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async update(data){
            const response = await fetch(config.URL_API + '/usuarios', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async baja(id){
            const response = await fetch(config.URL_API + '/usuarios/baja/'+id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
			});
            const responseData = await response.json();
            return responseData;
        },
        async alta(id){
            const response = await fetch(config.URL_API + '/usuarios/alta/'+id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
			});
            const responseData = await response.json();
            return responseData;
        },
        async getAllFranquicias(){
            const response = await fetch(config.URL_API + `/usuarios/franquicias`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const responseData = await response.json();
            return responseData;
        },
        async getAllRoles() {
            const response = await fetch(config.URL_API + '/usuarios/roles', {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async getAllSistemas() {
            const response = await fetch(config.URL_API + '/usuarios/sistemas', {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async changePassword(id, data){
            const response = await fetch(config.URL_API + '/password/'+id, {
			  method: 'PUT',
			  headers: {
			    'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
			  },
			  body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async blanquearClave(id, data){
            const response = await fetch(config.URL_API + '/blanqueo/'+id, {
			  method: 'PUT',
			  headers: {
			    'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
			  },
			  body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        }
    },
    rango_horario:{
        async getAll() {
            const response = await fetch(config.URL_API + '/rangoshorarios', {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
    },
    comprobantes_tipos:{
        async create(data) {
            const response = await fetch(config.URL_API + '/comprobantestipos', {
			  method: 'POST',
			  headers: {
			    'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
			  },
			  body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async getAll(query = '') {
            const response = await fetch(config.URL_API + `/comprobantestipos?` + query, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/comprobantestipos/${id}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async update(data){
            const response = await fetch(config.URL_API + '/comprobantestipos', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async delete(id){
            const response = await fetch(config.URL_API + '/comprobantestipos/'+id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
			});
            const responseData = await response.json();
            return responseData;
        },
    },
    clientes:{
        async create(data) {
            const response = await fetch(config.URL_API + '/clientes', {
			  method: 'POST',
			  headers: {
			    'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
			  },
			  body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async getAll(query = '') {
            const response = await fetch(config.URL_API + `/clientes?${query}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/clientes/${id}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async update(data){
            const response = await fetch(config.URL_API + '/clientes', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async baja(id){
            const response = await fetch(config.URL_API + '/clientes/baja/'+id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
			});
            const responseData = await response.json();
            return responseData;
        },
        async alta(id){
            const response = await fetch(config.URL_API + '/clientes/alta/'+id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
			});
            const responseData = await response.json();
            return responseData;
        },
    },
    clientes_domicilios:{
        async create(data) {
            const response = await fetch(config.URL_API + '/clientes/domicilios', {
			  method: 'POST', 
			  headers: {
			    'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
			  },
			  body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async getAll(id, query = '') {
            const response = await fetch(config.URL_API + `/clientes/${id}/domicilios?` + query, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/clientes/domicilios/${id}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async update(data){
            const response = await fetch(config.URL_API + '/clientes/domicilios', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async delete(id){
            const response = await fetch(config.URL_API + '/clientes/domicilios/'+id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
			});
            const responseData = await response.json();
            return responseData;
        },
    },
    clientes_contactos:{
        async create(data) {
            const response = await fetch(config.URL_API + '/clientes/contactos', {
			  method: 'POST',
			  headers: {
			    'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
			  },
			  body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async getAll(id, query = '') {
            const response = await fetch(config.URL_API + `/clientes/${id}/contactos?` + query, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/clientes/contactos/${id}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async update(data){
            const response = await fetch(config.URL_API + '/clientes/contactos', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async delete(id){
            const response = await fetch(config.URL_API + '/clientes/contactos/'+id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
			});
            const responseData = await response.json();
            return responseData;
        },
    },
    clientes_convenios:{
        async create(data) {
            const response = await fetch(config.URL_API + '/clientes/convenios', {
			  method: 'POST',
			  headers: {
			    'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
			  },
			  body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async getAll(id, query = '') {
            const response = await fetch(config.URL_API + `/clientes/${id}/convenios`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/clientes/convenios/${id}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async update(data){
            const response = await fetch(config.URL_API + '/clientes/convenios', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async delete(id){
            const response = await fetch(config.URL_API + '/clientes/convenios/'+id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
			});
            const responseData = await response.json();
            return responseData;
        },
    },
    condiciones_iva:{
        async getAll(query = '') {
            const response = await fetch(config.URL_API + `/condicioniva?${query}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
    },
    provincias:{
        async getAll() {
            const response = await fetch(config.URL_API + `/provincias`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
    },
    documentos_tipos:{
        async getAll() {
            const response = await fetch(config.URL_API + `/documentotipos`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
    },
    frecuencias:{
        async create(data) {
            const response = await fetch(config.URL_API + '/frecuencias', {
			  method: 'POST',
			  headers: {
			    'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
			  },
			  body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async getAll(query = '') {
            const response = await fetch(config.URL_API + `/frecuencias?` + query, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/frecuencias/`+id, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async update(data){
            const response = await fetch(config.URL_API + '/frecuencias', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async delete(id){
            const response = await fetch(config.URL_API + '/frecuencias/'+id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
			});
            const responseData = await response.json();
            return responseData;
        }
    },
    clientes_acuerdos_tipos:{
        async getAll() {
            const response = await fetch(config.URL_API + `/clientes/acuerdos/tipos`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
    },
    franquicias:{
        async create(data) {
            const response = await fetch(config.URL_API + '/franquicias', {
			  method: 'POST',
			  headers: {
			    'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
			  },
			  body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async getAll(query = '') {
            const response = await fetch(config.URL_API + `/franquicias?` + query, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/franquicias/${id}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async delete(id){
            const response = await fetch(config.URL_API + '/franquicias/'+id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
			});
            const responseData = await response.json();
            return responseData;
        },
        async getAtiende(longitud,latitud,cp) {
            const response = await fetch(config.URL_API + `/franquicias/atiende?longitud=${longitud}&latitud=${latitud}&cp=${cp}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const responseData = await response.json();
            return responseData;
        },
        async getNoAtiende(longitud,latitud,cp) {
            const response = await fetch(config.URL_API + `/franquicias/noatiende?longitud=${longitud}&latitud=${latitud}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const responseData = await response.json();
            return responseData;
        },
        async update(data){
            const response = await fetch(config.URL_API + '/franquicias', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async getAllNodos() {
            const response = await fetch(config.URL_API + `/nodos/todos`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
    },
    clientes_autogestion: {
        async create(data) {
            const response = await fetch(config.URL_API + '/clientes/autogestion', {
			  method: 'POST',
			  headers: {
			    'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
			  },
			  body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async getAll(id, query = '') {
            const response = await fetch(config.URL_API + `/clientes/${id}/autogestion?${query}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/clientes/autogestion/${id}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async update(data){
            const response = await fetch(config.URL_API + `/clientes/autogestion`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async baja(id){
            const response = await fetch(config.URL_API + '/clientes/autogestion/baja/'+id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
			});
            const responseData = await response.json();
            return responseData;
        },
        async alta(id){
            const response = await fetch(config.URL_API + '/clientes/autogestion/alta/'+id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
			});
            const responseData = await response.json();
            return responseData;
        },
        async changePassword(id, data){
            const response = await fetch(config.URL_API + '/clientes/autogestion/password/'+id, {
			  method: 'PUT',
			  headers: {
			    'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
			  },
			  body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async blanquearClave(id, data){
            const response = await fetch(config.URL_API + '/clientes/autogestion/blanqueo/'+id, {
			  method: 'PUT',
			  headers: {
			    'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
			  },
			  body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        }
    },
    articulos:{
        async create(data) {
            const response = await fetch(config.URL_API + '/articulos', {
			  method: 'POST',
			  headers: {
			    'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
			  },
			  body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async getAll(query = '') {
            const response = await fetch(config.URL_API + `/articulos?` + query, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/articulos/${id}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async getByMedidas(query) {
            const response = await fetch(config.URL_API + `/articulos/bultos?` + query, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async update(data){
            const response = await fetch(config.URL_API + '/articulos', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async baja(id){
            const response = await fetch(config.URL_API + '/articulos/baja/'+id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
			});
            const responseData = await response.json();
            return responseData;
        },
    },
    afip:{
        async consultarCuit(cuit = ''){
            const response = await fetch(`http://18.231.90.209:5600/api/afip/cuit/${cuit}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
    },
};
