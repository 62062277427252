import React from 'react';
import {api} from './api';
import { connect } from 'react-redux';
import { message,Steps, Modal, Form, Input, Select, Row, Col, Button, Spin, AutoComplete, Divider, Table, Tooltip, Switch, DatePicker, InputNumber } from 'antd';
import queryString from 'query-string';
import {utils} from 'utils'
import _ from 'lodash'
import moment from 'moment'
import './styles.scss'

import NuevoContacto from 'routes/clientes/components/contactos/Nuevo'
import NuevoDomicilio from 'routes/clientes/components/domicilios/Nuevo'
import NuevoCliente from '../clientes/Nuevo'

const FormItem = Form.Item;
const Option = Select.Option;
const AutoCompleteOption = AutoComplete.Option;
const Step = Steps.Step;

const steps = [{
  title: 'Remitente',
  content: 'First-content',
},{
  title: 'Destinatario',
  content: 'Second-content',
},{
  title: 'Bultos',
  content: 'Last-content',
},{
  title: 'Resumen',
  content: 'Last-content',
}];

class Nuevo extends React.Component {
constructor(props) {
super(props);
this.state = {
current: 0,
disabledLogin: false,
confirmLoading: false,
            autoCompleteClientes: [],
            autoCompleteClientesDestino: [],
            autoCompleteClientesOrigen: [],
            search:'',
            bultos: [],
            loadingBultos: false,
            columns: [{
              title: 'Articulo',
              dataIndex: 'articulo',
              key: 'articulo',
              render: (col, row) => row.articulo.articulo,
            },{
              title: 'Precio',
              dataIndex: 'precio',
              key: 'precio',
              render: (col, row) => utils.currency(row.articulo.precio),
            },{
              title: 'Dimensiones',
              dataIndex: 'z',
              key: 'z',
              render: (col, row) => { return row.z !== undefined ? `${row.z}x${row.x}x${row.y}` : ''},
            },{
              title: 'Peso',
              dataIndex: 'peso',
              key: 'peso',
            },{
              title: 'Valor Asegurado',
              dataIndex: 'valorasegurado',
              key: 'valorasegurado',
              render: (col, row) => {return row.valorasegurado !== undefined ? utils.currency(row.valorasegurado) : ''},
            },{
              title: 'Acción',
              dataIndex: 'accion',
              key: 'accion',
              render: (col, row, index) => {
                return <Tooltip placement="top" title={'Borrar'}>
                    <Button type="danger" icon="delete" onClick={()=>this.rmBulto(index)} disabled={this.state.current !== 2}></Button>
                </Tooltip>
              },
          }],
          errorDireccion: "",
          warningDireccion: "",
};
}

async componentDidMount(){
        this.props.form.setFieldsValue({
            fecha: moment(),
            oclientes_id: this.props.user.cliente.cliente
        });
        this.fetch()
    }
    fetch = async () =>{
        try {
            //Servicios
            const responseServicios = await api.servicios.getAll(queryString.stringify({
                limit: 100,
                page: 1,
            }))
            if (responseServicios.status === "success") {
                this.setState({
                    servicios: responseServicios.data.servicios,
                })
            }else {
                message.error(responseServicios.message)
            }

            //Rangos Horarios
            const responseRangosHorarios = await api.rangos_horarios.getAll(queryString.stringify({
                limit: 100,
                page: 1,
            }))
            if (responseRangosHorarios.status === "success") {
                this.setState({
                    horarios: responseRangosHorarios.data.rangos,
                })
            }else {
                message.error(responseRangosHorarios.message)
            }

            //Domicilio ------------------------------------------------------------------------------
            const response = await api.clientes_domicilios.getAll(this.props.user.clientes_id);
            if(response.status === "success"){
                this.setState({domicilios: response.data.clientes_domicilios})
            }

            //Contactos ------------------------------------------------------------------------------
            const responseContactos = await api.clientes_contactos.getAll(this.props.user.clientes_id);
            if(responseContactos.status === "success"){
                this.setState({contactos: responseContactos.data.clientes_contactos})
            }
        } catch (e) {
            message.error(e.toString(), 7);
        }
    }

next = () => {

        if (this.state.errorDireccion !== "") {
            return;
        }

this.props.form.validateFields(async (err, values) => {


            if (this.state.current === 1 && !this.state.cliente_destino) {
                this.props.form.setFieldsValue({
                    dclientes_id: "",
                })
            }

            if (this.state.current === 2) {

                if (!err && this.state.bultos.length > 0) {
            const current = this.state.current + 1;
            this.setState({ current });
                    return;
         }else {
                    message.error("Agregue al menos un articulo", 10);
                    return;
                }
            }

            if (!err) {
         const current = this.state.current + 1;
         this.setState({ current });
      }

})
}

prev = () => {
        this.props.form.validateFields(async (err, values) => {
            if (!err) {
        const current = this.state.current - 1;
        this.setState({ current });
            }
        })
}

handleSubmit = (e) => {
e.preventDefault();

		this.props.form.validateFields(async (err, values) => {
			if (!err) {
				try {
					this.setState({
						confirmLoading: true,
					})
                    const response = await api.retiros.create({
                        ...values,
                        franquicias_id: this.state.franquicias_id ? this.state.franquicias_id : parseInt(this.props.user.franquicias_id),
                        fecha: values.fecha.format("YYYY-MM-DDT00:00:00Z"),
                        clientes_id: parseInt(values.pagodestino === true ? this.state.cliente_destino.id : this.props.user.clientes_id),
                        clientes_convenios_id: parseInt(values.pagodestino === true ? values.dclientes_convenios_id : values.oclientes_convenios_id),
                        hora: parseInt(values.hora),
                        entrega: values.entrega,
                        pagodestino: values.pagodestino,
                        guia: {
                            oclientes_id: parseInt(this.props.user.clientes_id),
                            odomicilio_id: parseInt(values.odomicilio_id),
                            ocontacto_id: parseInt(values.ocontacto_id),
                            dclientes_id: parseInt(values.dclientes_id),
                            ddomicilio_id: parseInt(values.ddomicilio_id),
                            dcontacto_id: parseInt(values.dcontacto_id),
                        },
                        detalle: this.state.bultos.map((data, index) => {
                            return {
                                articulos_id: parseInt(data.articulo.id),
                                x: parseFloat(data.x),
                                y: parseFloat(data.y),
                                z: parseFloat(data.z),
                                peso: parseFloat(data.peso),
                                valorasegurado: parseFloat(data.valorasegurado),
                            }
                        })
                    })
                    if (response.status === "success") {
                    }else {
                        message.error(response.message, 7);
                    }
} catch (e) {
message.error(e.toString(), 7);
} finally {
this.setState({
confirmLoading: false,
                    })
                    this.props.closeModal();
				}
			}
		})
	}

    handleClienteOrigen = async (value) => {
        this.props.form.setFieldsValue({
            oclientes_id: "",
            odomicilio_id: "",
            ocontacto_id: "",
        })
        this.setState({
            cliente_origen: null,
        })
        if(value.trim().length >= 3 ){
            try {
                this.setState({ fetchingOrigen: true });

                const response = await api.clientes.getAll(queryString.stringify({
                    q: value.trim(),
                    limit: 50,
                    page: 1,
                }))

                this.setState({
                    autoCompleteClientesOrigen: response.data.clientes ? response.data.clientes.map((data, index) => {
                        return {
                            text: `${data.cliente}`,
                            value: data.id,
                        }}) : [],
                    fetchingOrigen: false,
                });

            } catch (e) {
                message.error(e.toString(), 7);
            }
        }else if(value.trim().length === 0) {
            this.setState({autoCompleteClientesOrigen:[]})
        }
    }

    handleClienteDestino = async (value) => {
        this.props.form.setFieldsValue({
            dclientes_id: "",
            ddomicilio_id: "",
            dcontacto_id: "",
        })
        this.setState({
            cliente_destino: null,
        })
        if(value.trim().length >= 3 ){
            try {
                this.setState({ fetchingDestino: true });

                const response = await api.clientes.getAll(queryString.stringify({
                    q: value.trim(),
                    limit: 50,
                    page: 1,
                }))

                this.setState({
                    autoCompleteClientesDestino: response.data.clientes ? response.data.clientes.map((data, index) => {
                        return {
                            text: `${data.cliente}`,
                            value: data.id,
                        }}) : [],
                    fetchingOrigen: false,
                });

            } catch (e) {
                message.error(e.toString(), 7);
            }
        }else if(value.trim().length === 0) {
            this.setState({autoCompleteClientesDestino:[]})
        }
    }


    onSelectCliente = async (idcliente, name) => {
        try {
            //Cliente
            this.setState({confirmLoading:true})
            const response = await api.clientes.get(idcliente);

            if (response.status === "success") {
                this.setState({
                    [name]: response.data.cliente,
                }, async ()=>{
                    if (name === "cliente_origen") {
                        this.props.form.setFieldsValue({
                            odomicilio_id: (this.state.cliente_origen && this.state.cliente_origen.domicilios[0]) ? this.state.cliente_origen.domicilios[0].id : '',
                            ocontacto_id: (this.state.cliente_origen && this.state.cliente_origen.contactos[0]) ? this.state.cliente_origen.contactos[0].id : '',
                        }, async () => {
                            //eslint-disable-next-line
                            const response = await api.franquicias.getAtiende(this.state.cliente_origen.domicilios[0].longitud, this.state.cliente_origen.domicilios[0].latitud, this.state.cliente_origen.domicilios[0].cp)

                        })

                        this.setState({
                            domicilio_origen: (this.state.cliente_origen && this.state.cliente_origen.domicilios[0]) ? this.state.cliente_origen.domicilios[0] : '',
                            contacto_origen: (this.state.cliente_origen && this.state.cliente_origen.contactos[0]) ? this.state.cliente_origen.contactos[0] : '',
                            errorDireccion: '',
                        }, ()=>{
                            this.checkDireccionRetiro(this.state.domicilio_origen);
                        })

                        //Convenios
                        const responseConvenios = await api.clientes_convenios.getAll(idcliente, queryString.stringify({
                            limit: 50,
                            page: 1,
                        }))

                        if (responseConvenios.status === "success") {
                            this.setState({
                                oconvenios: responseConvenios.data.clientes_convenios,
                            }, ()=>{
                                this.props.form.setFieldsValue({
                                    oclientes_convenios_id: (responseConvenios.data.clientes_convenios && responseConvenios.data.clientes_convenios[0]) ? responseConvenios.data.clientes_convenios[0].id : ''
                                })
                            })
                        }
                    }else if (name === "cliente_destino") {
                        this.props.form.setFieldsValue({
                            ddomicilio_id: (this.state.cliente_destino && this.state.cliente_destino.domicilios[0]) ? this.state.cliente_destino.domicilios[0].id : '',
                            dcontacto_id: (this.state.cliente_destino && this.state.cliente_destino.contactos[0]) ? this.state.cliente_destino.contactos[0].id : '',
                        })
                        this.setState({
                            domicilio_destino: (this.state.cliente_destino && this.state.cliente_destino.domicilios[0]) ? this.state.cliente_destino.domicilios[0] : '',
                            contacto_destino: (this.state.cliente_destino && this.state.cliente_destino.contactos[0]) ? this.state.cliente_destino.contactos[0] : '',
                        })

                        //franquicia
                        try {
                            const responseFranquicia = await api.franquicias.getAtiende(this.state.cliente_destino.domicilios[0].longitud,this.state.cliente_destino.domicilios[0].latitud, this.state.cliente_destino.domicilios[0].cp);
                            if (responseFranquicia.status === "success") {
                                this.setState({
                                    dfranquicia: responseFranquicia.data.franquicia ? responseFranquicia.data.franquicia : null,
                                })
                            }
                        } catch (e) {
                            message.error(e.toString())
                        }

                        //Convenios
                        const responseConvenios = await api.clientes_convenios.getAll(idcliente, queryString.stringify({
                            limit: 50,
                            page: 1,
                        }))
                        if (responseConvenios.status === "success") {
                            this.setState({
                                dconvenios: responseConvenios.data.clientes_convenios,
                            }, ()=>{
                                this.props.form.setFieldsValue({
                                    dclientes_convenios_id: (responseConvenios.data.clientes_convenios && responseConvenios.data.clientes_convenios[0]) ? responseConvenios.data.clientes_convenios[0].id : ''
                                })
                            })
                        }
                    }
                })
            }else {
                message.error(response.message, 7);
            }
        } catch (e) {
            message.error(e.toString(), 7);

        } finally{
          this.setState({confirmLoading:false})
        }
    }

    handleInputChange = (e, name) => {
        this.setState({
            [name]: e,
        })
    }

    searchBulto = async (e) => {
        e.preventDefault();
        this.setState({loadingBultos: true});
        try {
            const response = await api.articulos.getByMedidas(queryString.stringify({
                volumen: this.state.alto !== undefined ? (this.state.alto * this.state.largo * this.state.ancho) : null,
                peso: this.state.peso !== undefined ? this.state.peso : null,
                servicios_id: this.state.servicios_id,
            }))

            if (response.status === "success") {
                this.setState({
                    articulos_id: response.data.articulo.id,
                })

            }
        } catch (e) {
            message.error(e.toString(), 7)
        }

        this.setState({loadingBultos: false});
    }

    addBulto = () => {

        const articulos = _.find(this.state.articulos, ['id', this.state.articulos_id]);

        let bultos = [...this.state.bultos];
        bultos.push({
            articulo: articulos,
            z: this.state.alto,
            x: this.state.largo,
            y: this.state.ancho,
            peso: this.state.peso,
            valorasegurado: this.state.valorasegurado,
        })
        this.setState({
            bultos: bultos,
            alto: null,
            largo: null,
            ancho: null,
            peso: null,
            valorasegurado: null,
        })
    }

    rmBulto = (index) =>{
        let bultos = [...this.state.bultos];
        bultos.splice(index, 1);
        this.setState({
            bultos: bultos,
        })
    }

    getConvenio = () => {
        let convenios = this.props.form.getFieldValue("pagodestino") === true ? this.state.dconvenios : this.state.oconvenios;
        let idconvenio = this.props.form.getFieldValue("pagodestino") === true ? this.props.form.getFieldValue("dclientes_convenios_id") : this.props.form.getFieldValue("oclientes_convenios_id");
        const convenio = _.find(convenios, ['id', idconvenio]);
        return convenio && convenio.convenio ? convenio.convenio : '';
    }

    checkDireccionRetiro = async (domicilio) => {
        this.setState({
            franquicias_id: null,
        }, async () => {
            try {
                const responseFranquicia = await api.franquicias.getAtiende(domicilio.longitud, domicilio.latitud, domicilio.cp)
                let {franquicia} = responseFranquicia.data;

                if (franquicia) {
                    if (franquicia.id === 0) {
                        this.setState({errorDireccion: 'La dirección no es atendida por ninguna franquicia'})
                    }else {
                        if (franquicia.id !== this.props.user.franquicias_id) {
                            this.setState({
                                warningDireccion: `Esta dirección es atendida por: ${franquicia.franquicia}`,
                                franquicias_id: franquicia.id,
                            })
                        }
                    }
                }else {
                    message.error('Error..', 7)
                }
            } catch (e) {
                message.error(e.toString(), 7);
            }
        })

    }

    selectDomicilio = async (id, name) => {
        if (name === 'origen') {
            const domicilio = _.find(this.state.domicilios, ['id', id])
            this.setState({
                domicilio_origen: domicilio,
                errorDireccion: '',
                warningDireccion: '',
            }, () => {
                this.checkDireccionRetiro(domicilio);
            })
        }else {
            const domicilio = _.find(this.state.cliente_destino.domicilios, ['id', id])
            this.setState({
                domicilio_destino: domicilio,
            })
            try {
                const responseFranquicia = await api.franquicias.getAtiende(domicilio.longitud, domicilio.latitud, domicilio.cp);
                if (responseFranquicia.status === "success") {
                    this.setState({
                        dfranquicia: responseFranquicia.data.franquicia ? responseFranquicia.data.franquicia : null,
                    })
                }
            } catch (e) {
                message.error(e.toString())
            }
        }
    }

    selectContacto = (id, name) => {
        if (name === 'origen') {
            const contacto = _.find(this.state.contactos, ['id', id])
            this.setState({
                contacto_origen: contacto,
            })
        }else {
            const contacto = _.find(this.state.contactos, ['id', id])
            this.setState({
                contacto_destino: contacto,
            })
        }
    }

    handleSelectServicio = async(id) =>{
      this.setState({confirmLoading: true});
      //Articulos
       const responseArticulos = await api.articulos.getArticulos(queryString.stringify({
          servicios_id:id,
          clientes_ido:this.props.user.clientes_id,
          clientes_idd:this.state.cliente_destino.id,
          rubors_id:0,
          franquicias_ido:this.state.franquicias_id,
          franquicias_idd:this.state.dfranquicia.id
        }))
        if (responseArticulos.status === "success") {
            this.setState({
                articulos: responseArticulos.data.articulos,
            })
        }else {
            message.error(responseArticulos.message)
        }
        this.setState({
            servicios_id: id,
        })
        this.setState({confirmLoading: false});
    }

    disabledDate = (current) => {
        return current && current < moment().endOf('day').subtract(1,'days');
    }

    disabledTime = (current) => {
        return false ;
    }

    getHora = (id) => {
        if (this.state.horarios) {
            const hora = _.find(this.state.horarios, ['id', id]);
            return hora.rango;
        }
        return ""
    }

render() {
        const dom = this.state.domicilios;
        const contacto = this.state.contactos;
        const dfranquicia = this.state.dfranquicia;
        const { getFieldDecorator } = this.props.form;
        const { current, fetchingDestino, autoCompleteClientesDestino } = this.state;
        const clientesDestinoOptions = autoCompleteClientesDestino.map(data => (
          <AutoCompleteOption key={data.value}>{data.text.trim()}</AutoCompleteOption>
        ));
return (
<Modal
 visible={true}
 confirmLoading={this.state.confirmLoading}
 footer={null}
 width={960}
 style={{top:40}}
>
<section className="form-v1-container col-md-12">
                <Spin tip="Loading..." spinning={this.state.confirmLoading}>
<Steps
current={current}
size="small"
labelPlacement="vertical"
>
 	{steps.map(item => <Step key={item.title} title={item.title} />)}
</Steps>
<div className="steps-content">

                            <Form>
    {/*STEP 0*/}
                                <div style={current === 0 ? {} : {display:'none'}}>
                                        <Row gutter={16}>
                                            <Col span={18}>
                                                <FormItem label="Remitente:" {...{
                                                labelCol: {sm: { span: 4 },},
                                                wrapperCol: {sm: { span: 20},},
                                                }}>
                                                    {getFieldDecorator('oclientes_id', {
                                                        initialValue: "",
                                                        rules: [{ required: current === 0 ? true : false, message: 'El campo es obligatorio.' }],
                                                        })(
                                                            <Input 
                                                            readOnly
                                                            />
                                                        )
                                                    }
                                                </FormItem>
                                            </Col>

                                            {/* <Col span={1}>
                                                <Tooltip placement="right" title={'Nuevo cliente'}>
                                                    <Button
                                                        icon="plus"
                                                        className="btn-small-add"
                                                        type="primary"
                                                        onClick={() => {
                                                            this.setState({
                                                                openNuevoCliente: true,
                                                                origen_destino: 'cliente_origen',
                                                            })}
                                                        }
                                                    ></Button>
                                                </Tooltip>
                                            </Col> */}
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={18}>
                                                <FormItem label="Dirección:" {...{
                                            labelCol: {sm: { span: 4 },},
                                            wrapperCol: {sm: { span: 20},},
                                            }}>
                                                {getFieldDecorator('odomicilio_id', {
                                                        initialValue: "",
                                                        rules: [{ required: current === 0 ? true : false, message: ' ' }],
                                                        })(
                                                        <Select
                                                        style={{width:"100%"}}
                                                        optionFilterProp="children"
                                                        onSelect={(e)=>this.selectDomicilio(e, 'origen')}
                                                        // disabled={!this.state.cliente_origen}
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        >
                                                            {dom && dom.map((data,index)=>{
                                                                return <Option value={data.id} key={index}>{`${data.calle}, ${data.altura}, ${data.localidad}`}</Option>
                                                            })}
                                                        </Select>
                                                    )
                                                }
                                                {this.state.errorDireccion !== "" &&
                                                    <div className="ant-form-item-control has-error">
                                                        <div className="ant-form-explain">{this.state.errorDireccion}</div>
                                                    </div>
                                                }
                                                {this.state.warningDireccion !== "" &&
                                                    <div className="ant-form-item-control has-warning">
                                                        <div className="ant-form-explain">{this.state.warningDireccion}</div>
                                                    </div>
                                                }
                                                </FormItem>
                                            </Col>
                                            <Col span={1}>
                                                <Tooltip placement="right" title={'Nuevo Domicilio'}>
                                                    <Button
                                                        icon="plus"
                                                        className="btn-small-add"
                                                        type="primary"
                                                        onClick={() => {
                                                            this.setState({
                                                                openNuevoDomicilio: true,
                                                                idcliente: this.props.user.clientes_id,
                                                                origen_destino: 'cliente_origen',
                                                            })}
                                                        }
                                                    >
                                                    </Button>
                                                </Tooltip>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={18}>
                                                <FormItem label="Contacto:" {...{
                                                labelCol: {sm: { span: 4 },},
                                                wrapperCol: {sm: { span: 20},},
                                                }}>
                                                {getFieldDecorator('ocontacto_id', {
                                                        initialValue: "",
                                                        rules: [{ required: current === 0 ? true : false, message: ' ' }],
                                                        })(
                                                        <Select
                                                        style={{width:"100%"}}
                                                        optionFilterProp="children"
                                                        onSelect={(e)=>this.selectContacto(e, 'origen')}
                                                        // disabled={!this.state.cliente_origen}
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        >
                                                            {contacto && contacto.map((data,index)=>{
                                                                return <Option value={data.id} key={index}>{data.contacto}</Option>
                                                            })}
                                                        </Select>
                                                    )
                                                }
                                                </FormItem>
                                            </Col>

                                            <Col span={1}>
                                                <Tooltip placement="right" title={'Nuevo contacto'}>
                                                    <Button
                                                        icon="plus"
                                                        className="btn-small-add"
                                                        type="primary"
                                                        onClick={() => {
                                                            this.setState({
                                                                openNuevoContacto: true,
                                                                idcliente:this.props.user.clientes_id,
                                                                origen_destino: 'cliente_origen',
                                                            })}
                                                        }
                                                    >
                                                    </Button>
                                                </Tooltip>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={9}>
                                                <FormItem label="Pago en destino:" {...{
                                                    labelCol: {sm: { span: 12 },},
                                                    wrapperCol: {sm: { span: 12 },},
                                                }}>
                                                {getFieldDecorator('pagodestino', {
                                                    initialValue: false,
                                                    rules: [{ required: false, message: ' ' }],
                                                    })(
                                                        <Switch />
                                                    )
                                                }
                                                </FormItem>
                                            </Col>

                                            <Col span={12}>
                                                <FormItem label="Entrega a Domicilio:" {...{
                                                    labelCol: {sm: { span: 10 },},
                                                    wrapperCol: {sm: { span: 12 },},
                                                }}>
                                                {getFieldDecorator('entrega', {
                                                    initialValue: false,
                                                    rules: [{ required: false, message: ' ' }],
                                                    })(
                                                        <Switch />
                                                    )
                                                }
                                                </FormItem>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <FormItem label="Convenio:" {...{
                                                    labelCol: {sm: { span: 6 },},
                                                    wrapperCol: {sm: { span: 18 },},
                                                }}>
                                                    {getFieldDecorator('oclientes_convenios_id', {
                                                            // rules: [{ required: current == 0 ? true : false, message: ' ' }],
                                                            })(
                                                            <Select
                                                            placeholder="Seleccionar"
                                                            style={{width:"100%"}}
                                                            optionFilterProp="children"
                                                            showSearch
                                                            filterOption={(input, option) =>
                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            >
                                                                {this.state.oconvenios && this.state.oconvenios.map((data,index)=>{
                                                                    return <Option value={data.id} key={index}>{data.convenio}</Option>
                                                                })}
                                                            </Select>
                                                        )
                                                    }
                                                </FormItem>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={9}>
                                                <FormItem label="Fecha:" {...{
                                                    labelCol: {sm: { span:  8       },},
                                                    wrapperCol: {sm: { span: 12 },},
                                                }}>
                                                    {getFieldDecorator('fecha', {
                                                            rules: [{ required: current === 0 ? true : false, message: ' ' }],
                                                            })(
                                                            <DatePicker
                                                                format={"DD/MM/YYYY"}
                                                                disabledDate={this.disabledDate}
                                                                style={{width:"100%"}}
                                                                placeholder="Fecha"
                                                            />
                                                        )
                                                    }
                                                </FormItem>
                                            </Col>
                                            <Col span={9}>
                                                <FormItem label="Hora:" {...{
                                                    labelCol: {sm: { span: 5 },},
                                                    wrapperCol: {sm: { span: 12  },},
                                                }}>
                                                    {getFieldDecorator('hora')(
                                                        <Select
                                                        placeholder="Seleccionar"
                                                        style={{width:"100%"}}
                                                        optionFilterProp="children"
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        >
                                                            {this.state.horarios && this.state.horarios.map((data,index)=>{
                                                                return <Option value={data.id} key={index}>{data.rango}</Option>
                                                            })}
                                                        </Select>
                                                    )}
                                                </FormItem>
                                            </Col>
                                        </Row>

                                </div>

    {/*STEP 2*/}
                                <div style={current === 1 ? {} : {display:'none'}}>

                                        <Row gutter={16}>
                                            <Col span={18}>
                                                <FormItem label="Destinatario:" {...{
                                                    labelCol: {sm: { span: 4 },},
                                                    wrapperCol: {sm: { span: 20},},
                                                }}>
                                                {getFieldDecorator('dclientes_id', {
                                                    rules: [{ required: current === 2 ? true : false, message: ' ' }],
                                                    })(
                                                        <AutoComplete
                                                            dataSource={clientesDestinoOptions}
                                                            onChange={this.handleClienteDestino}
                                                            onSearch={()=>this.props.form.setFieldsValue({
                                                                dclientes_id: null,
                                                            })}
                                                            onSelect={(e)=>this.onSelectCliente(e, 'cliente_destino')}
                                                            notFoundContent={fetchingDestino ? <Spin size="small" /> : null}
                                                        >
                                                            <Input />
                                                        </AutoComplete>
                                                    )
                                                }
                                                </FormItem>
                                            </Col>

                                            <Col span={1}>
                                                <Tooltip placement="right" title={'Nuevo cliente'}>
                                                    <Button
                                                        icon="plus"
                                                        className="btn-small-add"
                                                        type="primary"
                                                        onClick={() => {
                                                            this.setState({
                                                                openNuevoCliente: true,
                                                                origen_destino: 'cliente_destino',
                                                            })}
                                                        }
                                                    ></Button>
                                                </Tooltip>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={18}>
                                                <FormItem label="Dirección:" {...{
                                                    labelCol: {sm: { span: 4 },},
                                                    wrapperCol: {sm: { span: 20},},
                                                }}>
                                                {getFieldDecorator('ddomicilio_id', {
                                                        initialValue: '',
                                                        rules: [{ required: current === 2 ? true : false, message: ' ' }],
                                                        })(
                                                        <Select
                                                        placeholder="Seleccionar"
                                                        style={{width:"100%"}}
                                                        optionFilterProp="children"
                                                        onSelect={(e)=>this.selectDomicilio(e, 'destino')}
                                                        disabled={!this.state.cliente_destino}
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        >
                                                            {this.state.cliente_destino && this.state.cliente_destino.domicilios && this.state.cliente_destino.domicilios.map((data,index)=>{
                                                                return <Option value={data.id} key={index}>{`${data.calle} ${data.altura} ${data.localidad}`}</Option>
                                                            })}
                                                        </Select>
                                                    )
                                                }
                                                </FormItem>
                                            </Col>

                                            <Col span={1}>
                                                <Tooltip placement="right" title={'Nuevo Domicilio'}>
                                                    <Button
                                                        icon="plus"
                                                        className="btn-small-add"
                                                        type="primary"
                                                        onClick={() => {
                                                            this.setState({
                                                                openNuevoDomicilio: true,
                                                                idcliente: this.state.cliente_destino ? this.state.cliente_destino.id : this.state.cliente_origen.id,
                                                                origen_destino: 'cliente_destino',
                                                            })}
                                                        }
                                                    >
                                                    </Button>
                                                </Tooltip>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={18}>
                                                <FormItem label="Contacto:" {...{
                                                    labelCol: {sm: { span: 4 },},
                                                    wrapperCol: {sm: { span: 20},},
                                                }}>
                                                {getFieldDecorator('dcontacto_id', {
                                                        initialValue: '',
                                                        rules: [{ required: current === 2 ? true : false, message: ' ' }],
                                                        })(
                                                        <Select
                                                        placeholder="Seleccionar"
                                                        style={{width:"100%"}}
                                                        optionFilterProp="children"
                                                        disabled={!this.state.cliente_destino}
                                                        onSelect={(e)=>this.selectContacto(e, 'destino')}
                                                        showSearch
                                                        filterOption={(input, option) =>
                                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        >
                                                            {this.state.cliente_destino && this.state.cliente_destino.contactos && this.state.cliente_destino.contactos.map((data,index)=>{
                                                                return <Option value={data.id} key={index}>{data.contacto}</Option>
                                                            })}
                                                        </Select>
                                                    )
                                                }
                                                </FormItem>
                                            </Col>

                                            <Col span={1}>
                                                <Tooltip placement="right" title={'Nuevo contacto'}>
                                                    <Button
                                                        icon="plus"
                                                        className="btn-small-add"
                                                        type="primary"
                                                        onClick={() => {
                                                            this.setState({
                                                                openNuevoContacto: true,
                                                                idcliente:this.state.cliente_destino.id,
                                                                origen_destino: 'cliente_destino',
                                                            })}
                                                        }
                                                        disabled={!this.state.cliente_destino}
                                                    >
                                                    </Button>
                                                </Tooltip>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <FormItem label="Convenio:" {...{
                                                    labelCol: {sm: { span: 6 },},
                                                    wrapperCol: {sm: { span: 18 },},
                                                }}>
                                                    {getFieldDecorator('dclientes_convenios_id', {
                                                            // rules: [{ required: current == 1 ? true : false, message: ' ' }],
                                                            })(
                                                            <Select
                                                            placeholder="Seleccionar"
                                                            style={{width:"100%"}}
                                                            optionFilterProp="children"
                                                            showSearch
                                                            filterOption={(input, option) =>
                                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            >
                                                                {this.state.dconvenios && this.state.dconvenios.map((data,index)=>{
                                                                    return <Option value={data.id} key={index}>{data.convenio}</Option>
                                                                })}
                                                            </Select>
                                                        )
                                                    }
                                                </FormItem>
                                            </Col>
                                        </Row>


                                </div>

    {/*STEP 3*/}
                                <div style={current === 2 ? {} : {display:'none'}}>
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <FormItem {...{
                                                labelCol: {sm: { span: 3 },},
                                                wrapperCol: {sm: { span: 8 },},
                                            }} label="Servicio:">
                                                <Select
                                                placeholder="Servicio"
                                                style={{width:"100%"}}
                                                optionFilterProp="children"
                                                name="servicios_id"
                                                value={this.state.servicios_id}
                                                onSelect={this.handleSelectServicio}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                >
                                                    {this.state.servicios && this.state.servicios.map((data,index)=>{
                                                        return <Option value={data.id} key={index}>{data.servicio}</Option>
                                                    })}
                                                </Select>
                                            </FormItem>
                                        </Col>
                                        <Col span={24}>
                                            <Form onSubmit={this.searchBulto}>
                                                <FormItem {...{
                                                    labelCol: {sm: { span: 3 },},
                                                    wrapperCol: {sm: { span: 21 },},
                                                }} label="Dimensiones:">
                                                    <Row gutter={8} >
                                                        <Col span={4}>
                                                            <InputNumber min={1} style={{width:"100%"}} name="alto" placeholder="Alto" onChange={(e)=>this.handleInputChange(e, 'alto')} value={this.state.alto} required disabled={!this.state.servicios_id}/>
                                                        </Col>
                                                        <Col span={4}>
                                                            <InputNumber min={1} style={{width:"100%"}} name="largo" placeholder="Largo" onChange={(e)=>this.handleInputChange(e, 'largo')} value={this.state.largo} required disabled={!this.state.servicios_id}/>
                                                        </Col>
                                                        <Col span={4}>
                                                            <InputNumber min={1} style={{width:"100%"}} name="ancho" placeholder="Ancho" onChange={(e)=>this.handleInputChange(e, 'ancho')} value={this.state.ancho} required disabled={!this.state.servicios_id}/>
                                                        </Col>
                                                        <Col span={4}>
                                                            <InputNumber min={1} style={{width:"100%"}} name="peso" placeholder="Peso" onChange={(e)=>this.handleInputChange(e, 'peso')}  value={this.state.peso} required disabled={!this.state.servicios_id}/>
                                                        </Col>
                                                        <Col span={5}>
                                                            <InputNumber min={dfranquicia && dfranquicia ? dfranquicia.seguro_minimo:0} max={dfranquicia&& dfranquicia?dfranquicia.seguro_maximo:9999999999} style={{width:"100%"}} name="valorasegurado" placeholder="Valor Asegurado" onChange={(e)=>this.handleInputChange(e, 'valorasegurado')} value={this.state.valorasegurado} required disabled={!this.state.servicios_id}/>
                                                        </Col>
                                                        <Col span={1}>
                                                            <Button icon="search" type="primary" htmlType="submit" disabled={!this.state.servicios_id}></Button>
                                                        </Col>
                                                    </Row>
                                                </FormItem>
                                            </Form>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={12}>
                                            <FormItem {...{
                                                labelCol: {sm: { span: 6 },},
                                                wrapperCol: {sm: { span: 18 },},
                                            }} label="Articulo:">
                                                <Select
                                                placeholder="Articulo"
                                                name="articulos_id"
                                                // value={this.state.articulos_id}
                                                onChange={(e) => this.handleInputChange(e, 'articulos_id')}
                                                loading={this.state.loadingBultos}
                                                disabled={!this.state.servicios_id}
                                                style={{width:"100%"}}
                                                optionFilterProp="children"
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                >
                                                    {this.state.articulos && this.state.articulos.map((data,index)=>{
                                                        return <Option value={data.id} key={index}>{data.codigo +' '+ data.articulo}</Option>
                                                    })}
                                                </Select>
                                            </FormItem>
                                        </Col>
                                        <Col style={{ float:'right'}} span={5}>
                                            <Button
                                                icon="plus"
                                                type="primary"
                                                htmlType="submit"
                                                style={{ marginTop:4 }}
                                                onClick={this.addBulto}
                                                disabled={!this.state.servicios_id}
                                            > Agregar </Button>
                                        </Col>
                                    </Row>
                                    <Table
                                        dataSource={this.state.bultos ? this.state.bultos : []}
                                        columns={this.state.columns}
                                        pagination={false}
                                    />
                                </div>

                                {/*STEP 4*/}
                                <div className="detalle" style={current === 3 ? {} : {display:'none'}}>
                                    <Row gutter={16}>
                                    <Col span={12}>
                                        <Col span={24}>
                                            <label>Cliente: </label>{this.props.form.getFieldValue("pagodestino") === true ? ( this.state.cliente_destino && this.state.cliente_destino.cliente) : (this.state.cliente_origen && this.state.cliente_origen.cliente)}
                                        </Col>
                                        <Col span={24}>
                                            <label>Convenio: </label>{(this.state.cliente_destino || this.state.cliente_origen) && this.getConvenio()}
                                        </Col>
                                        <Col span={24}>
                                            <label>Pago en Destino: </label>{this.props.form.getFieldValue("pagodestino") ? "SI" : "NO"}
                                        </Col>
                                    </Col>
                                    <Col span={12}>
                                        <Col span={24}>
                                            <label>Entrega a Domicilio: </label>{this.props.form.getFieldValue("entrega") ? "SI" : "NO"}
                                        </Col>
                                        <Col span={24}>
                                            <label>Fecha: </label>{this.props.form.getFieldValue("fecha") && this.props.form.getFieldValue("fecha").format("DD-MM-YYYY")}
                                        </Col>
                                        <Col span={24}>
                                            <label>Hora: </label>{this.props.form.getFieldValue("hora") && this.getHora(this.props.form.getFieldValue("hora"))}
                                        </Col>
                                    </Col>
                                    </Row>
                                    <Divider />
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Col span={24}>
                                                <label>Remitente: </label>{this.state.cliente_origen && this.state.cliente_origen.cliente}
                                            </Col>
                                            <Col span={24}>
                                                <label>Dirección: </label>{this.state.domicilio_origen ?
                                                    `${this.state.domicilio_origen.calle},
                                                    ${this.state.domicilio_origen.altura},
                                                    ${this.state.domicilio_origen.piso !== '' ? this.state.domicilio_origen.piso+"," : ''}
                                                    ${this.state.domicilio_origen.depto !== '' ? this.state.domicilio_origen.depto+"," : ''}
                                                    ${this.state.domicilio_origen.localidad},
                                                    ${this.state.domicilio_origen.provincia.provincia}` : ''}
                                            </Col>
                                            <Col span={24}>
                                                <label>Contacto:</label>{this.state.contacto_origen && this.state.contacto_origen.contacto}
                                            </Col>
                                        </Col>
                                        <Col span={12}>
                                            <Col span={24}>
                                                <label>Destinatario: </label>{this.state.cliente_destino && this.state.cliente_destino.cliente}
                                            </Col>
                                            <Col span={24}>
                                                <label>Dirección: </label>{this.state.domicilio_destino ?
                                                    `${this.state.domicilio_destino.calle},
                                                    ${this.state.domicilio_destino.altura},
                                                    ${this.state.domicilio_destino.piso !== '' ? this.state.domicilio_destino.piso+"," : ''}
                                                    ${this.state.domicilio_destino.depto !== '' ? this.state.domicilio_destino.depto+"," : ''}
                                                    ${this.state.domicilio_destino.localidad},
                                                    ${this.state.domicilio_destino.provincia.provincia}` : ''}
                                            </Col>
                                            <Col span={24}>
                                                <label>Contacto: </label>{this.state.contacto_destino && this.state.contacto_destino.contacto}
                                            </Col>
                                            <Col span={24}>
                                                <label>Franquicia: </label>{this.state.dfranquicia && this.state.dfranquicia.franquicia}
                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop:15}}>
                                        <Table
                                            dataSource={this.state.bultos ? this.state.bultos : []}
                                            columns={this.state.columns}
                                            pagination={false}
                                            loading={this.state.loadingBultos}
                                        />
                                    </Row>
                                </div>

                            </Form>

</div>

                        <div className="steps-action">
                            <Button type="danger"  onClick={() => this.props.closeModal()} style={{marginRight:8}}>
                                Cerrar
                            </Button>
                            {current > 0 &&
                                (<Button  onClick={() => this.prev()}>
                                    Atras
                                </Button>)
                            }
                            {current < steps.length - 1 &&
                                <Button type="primary" style={{ marginLeft: 8 }} onClick={() => this.next()}>Siguiente</Button>
                            }
                            {current === steps.length - 1 &&
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    style={{ marginLeft: 8 }}
                                    loading={this.state.confirmLoading}
                                    onClick={this.handleSubmit}
                                >
                                Finalizar
                                </Button>
                            }
                        </div>
                        <div style={{clear: "both"}}></div>
</Spin>
      </section>

                {this.state.openNuevoContacto &&
                    <NuevoContacto
                        closeModal={()=>this.setState({openNuevoContacto:false,})}
                        idcliente={this.props.user.clientes_id}
                      //  onCreate={()=>this.onSelectCliente(this.state.idcliente, this.state.origen_destino)}
                      onCreate={()=>{this.fetch()}}
                    />
                }

                {this.state.openNuevoDomicilio &&
                    <NuevoDomicilio
                        closeModal={()=>this.setState({openNuevoDomicilio:false,})}
                        idcliente={this.props.user.clientes_id}
                        save={true}
                        // onCreate={()=>this.onSelectCliente(this.state.idcliente, this.state.contactos)}
                        onCreate={()=>{this.fetch()}}
                    />
                }

                {this.state.openNuevoCliente &&
                    <NuevoCliente
                        closeModal={()=>this.setState({openNuevoCliente:false,})}
                        onCreate={()=>{}}
                    />
                }
</Modal>
);
}
}

const mapStateToProps = (state) => ({
user: state.user,
});
 
const WrappedNuevo = Form.create()(Nuevo);

export default connect(
  mapStateToProps,
)(WrappedNuevo);