import React from 'react';
import {api} from '../../api';
import { connect } from 'react-redux';
import { message, Modal, Form, Input, Select, Row, Col,Divider, Alert,Button } from 'antd';
import Geosuggest from 'react-geosuggest';
import '../../styles.scss';
import _ from 'lodash';
import queryString from 'query-string';
import Map from './Map'
const FormItem = Form.Item;
const Option = Select.Option;
const ButtonGroup = Button.Group;

class NuevoDomicilio extends React.Component {
    state = {
        disabledLogin: false,
        confirmLoading: false,
        urlmap:"https://maps.google.com.ar",
        atiende:"",
        NOatiende:'',
        OpenMaps: false,
        lat: null,
        lng: null,
        latitud: null,
        longitud: null,
        clientes_convenios_id: null,
        flag:true,
    }
    handleSelectChange = (e) =>{
      this.props.form.setFieldsValue({clientes_convenios_id: e})
      this.get_franquiciacobertura()
    }
    async componentDidMount(){
        try {
            //Provincias
            const responseProvincias = await api.provincias.getAll();
            if (responseProvincias.status === "success") {
                await this.setState({
                    provincias: responseProvincias.data.provincias,
                })
            }

            this.setState({...this.props})
            // pmarket por defecto para el grafico

            if (this.state.modal){
                if(this.props.destinatario === 'existe'){
                    // this.setState({ lat: this.props.user.franquicia.latitud, lng: this.props.user.franquicia.longitud});
                }
            }else{
                if(this.props.destinatario === 'existe'){
                    // this.setState({ lat: this.props.user.franquicia.latitud, lng: this.props.user.franquicia.longitud});
                }
            }
          //  this.state.latitud = this.state.domicilio.latitud
          //  this.state.longitud = this.state.domicilio.longitud
          if(this.props.destinatario === 'existe'){
                if(this.state.domicilio.latitud !== "" && this.state.domicilio.longitud !== ""){
                    if(this.state.domicilio.latitud !== "0" && this.state.domicilio.longitud !== "0"){
                    if(this.state.domicilio.latitud*1 !== 0 && this.state.domicilio.longitud*1 !== 0){
                        this.setState({latitud: this.props.domicilio.latitud, longitud: this.props.domicilio.longitud})
                    }
                    }
                }
            }
            
            // coordenadas del domicilio inicial
            if(this.props.destinatario === 'existe'){
            this.setState({cp : this.state.domicilio.cp})
            
            if(this.props.domicilio && this.props.domicilio.clientes_id > 0){
              //Convenios
              const responseConvenios = await api.clientes_convenios.getAll(this.props.domicilio.clientes_id, queryString.stringify({
                  limit: 50,
                  page: 1,
              }))
              if (responseConvenios.status === "success") {
                  this.setState({
                      convenios: responseConvenios.data.clientes_convenios,
                  })
                  if(this.state.domicilio.clientes_convenios_id > 0){
                    this.props.form.setFieldsValue({clientes_convenios_id: this.state.domicilio.clientes_convenios_id})
                  }else{
                    this.props.form.setFieldsValue({clientes_convenios_id: responseConvenios.data.clientes_convenios && responseConvenios.data.clientes_convenios[0] ? responseConvenios.data.clientes_convenios[0].id : null})
                  }

              }
            }else{
              this.setState({
                  convenios: null,
              })
            }
        }
            this.get_franquiciacobertura()
        } catch (e) {
            message.error(e.toString(), 7);
            console.log(e)
        }
    }


    async get_franquiciacobertura (){
        try {
          this.setState({atiende:"",NOatiende:"",longitud:this.props.form.getFieldValue("longitud"),latitud:this.props.form.getFieldValue("latitud")})
          const responseFranquicia = await api.franquicias.getAtiende(this.props.form.getFieldValue("longitud"),this.props.form.getFieldValue("latitud"),this.props.form.getFieldValue("cp"),this.props.form.getFieldValue("clientes_convenios_id"));
          if (responseFranquicia.status === "success" ) {
              if(responseFranquicia.data.franquicia.id !== 0){
                  this.setState({franquicia: responseFranquicia.data.franquicia, atiende:responseFranquicia.data.franquicia.codigo+"-"+ responseFranquicia.data.franquicia.franquicia,NOatiende:""})
                  this.get_franquiciaNOcobertura()
              }
          }
          this.setState({flag:false});
        } catch (e) {
            message.error(e.toString(), 7);
        }

    }

    async get_franquiciaNOcobertura (){
        try {
            const responseFranquicia = await api.franquicias.getNoAtiende(this.props.form.getFieldValue("longitud"),this.props.form.getFieldValue("latitud"),this.props.form.getFieldValue("cp"));
            if (responseFranquicia.status === "success" ) {
                if(responseFranquicia.data.franquicia_zona.id !== 0){
                    this.setState({NOatiende:"Domicilio excluido de ENTREGA o RETIRO a domicilio. " + responseFranquicia.data.franquicia_zona.zona})
                }else{
                    this.setState({NOatiende:""})
                }

            }

        } catch (e) {
            message.error(e.toString(), 7);
        }

    }
    handleSubmit = (e) => {
        e.preventDefault();
        // let _this = this
        try{
        // if(_this.props.destinatario === 'existe'){
        // let calle = this.props.form.getFieldValue('calle') ||''
        // let cp    = this.props.form.getFieldValue('cp') ||''
        // let altura    = this.props.form.getFieldValue('altura') ||''
        // this.props.form.setFieldsValue({calle: calle.trim()})
        // this.props.form.setFieldsValue({cp:  cp.trim()})
        // this.props.form.setFieldsValue({altura:  altura.trim()})
        
        
        //     _this.props.form.validateFields(async (err, values) => {
        //     if (!err) {
        //         _this.props.onReturn({
        //             ...values,
        //             latitud: values.latitud !== null ? values.latitud.toString() : '',
        //             longitud: values.longitud !== null ? values.longitud.toString() : '',
        //             })
        //     }
        //   })
        // }else if(_this.props.destinatario === ''){
        //     this.props.form.validateFields(async (err, values) => {
        //         if (!err) {
        //             // console.log('val: ', values, 'state: ' ,this.state)
        //             const ind = this.state.provincias.findIndex(item => item.id === values.provincias_id);
        //             this.props.cargarDatos({
        //                 calle: values.calle,
        //                 altura: values.altura,
        //                 piso: values.piso,
        //                 depto: values.depto,
        //                 localidad: values.localidad,
        //                 cp: values.cp,
        //                 provincia: this.state.provincias[ind],
        //                 provincias_id: values.provincias_id,
        //                 clientes_convenios_id: values.clientes_convenios_id,
        //                 latitud: values.latitud,
        //                 longitud: values.longitud,
        //                 franquicia_idd: this.state.franquicia && this.state.franquicia.id,
        //             });
        //         }
        //     })
        // }
                this.props.form.validateFields(async (err, values) => {
                if (!err) {
                    // console.log('val: ', values, 'state: ' ,this.state)
                    const ind = this.state.provincias.findIndex(item => item.id === values.provincias_id);
                    this.props.cargarDatos({
                        calle: values.calle,
                        altura: values.altura,
                        piso: values.piso,
                        depto: values.depto,
                        localidad: values.localidad,
                        cp: values.cp,
                        provincia: this.state.provincias[ind],
                        provincias_id: values.provincias_id,
                        clientes_convenios_id: values.clientes_convenios_id,
                        latitud: values.latitud,
                        longitud: values.longitud,
                        franquicia_idd: this.state.franquicia && this.state.franquicia.id,
                    });
                }
            })
        }catch(e){
            message.error(e.toString(), 7)
            console.log(e)
        }finally{
            if(this.state.modal === true){
                this.props.closeModal()
                // if (this.props.destinatario === '1'){
                //     this.props.selectServicio()
                // }
            }
        }
    }

    handleCpChange = (e) => {
        e.preventDefault();
        this.get_franquiciacobertura()
    }
    onSuggestSelect = (suggest) => {
        if (suggest && suggest.gmaps) {

            this.setState({
                localidad: null,
                calle: null,
                altura: null,
                cp: null,
                latitud: null,
                longitud: null,
                provincias_id: null,
                idprovincia: null,
                domiciliotipos_id: 2,
            }, () => {
                let localidad="";
                if (suggest && suggest.gmaps && suggest.gmaps.address_components) {
                    for (var i = 0; i < suggest.gmaps.address_components.length; i++) {
                        if (suggest.gmaps.address_components[i].types.indexOf('route') !== -1) {
                            this.props.form.setFieldsValue({calle: suggest.gmaps.address_components[i].long_name})
                        }
                        if (suggest.gmaps.address_components[i].types.indexOf('locality') !== -1) {
                            this.props.form.setFieldsValue({localidad: suggest.gmaps.address_components[i].long_name})
                            localidad=suggest.gmaps.address_components[i].long_name;
                        }
                        if (suggest.gmaps.address_components[i].types.indexOf('sublocality') !== -1) {
                            this.props.form.setFieldsValue({localidad: (localidad !==""?localidad:"CABA -") + suggest.gmaps.address_components[i].long_name})
                        }
                        if (suggest.gmaps.address_components[i].types.indexOf('postal_code') !== -1) {
                            this.props.form.setFieldsValue({cp: suggest.gmaps.address_components[i].long_name})
                        }
                        if (suggest.gmaps.address_components[i].types.indexOf('street_number') !== -1) {
                            this.props.form.setFieldsValue({altura: suggest.gmaps.address_components[i].long_name})
                        }
                        if (suggest.gmaps.address_components[i].types.indexOf('administrative_area_level_1') !== -1) {
                            let prov = _.deburr(suggest.gmaps.address_components[i].short_name)
                            prov = prov.replace('Provincia de ','')

                            if(prov === "CABA"){
                                this.props.form.setFieldsValue({provincias_id: _.find(this.state.provincias, ["provincia","Ciudad Autónoma de Buenos Aires"]).id});
            //                    this.props.form.setFieldsValue({idprovincia: _.find(this.state.provincias, ["provincia","Ciudad Autónoma de Buenos Aires"]).id});
                            }else{
                                this.props.form.setFieldsValue({provincias_id: _.find(this.state.provincias, ["provincia",prov]).id});
          //                      this.props.form.setFieldsValue({idprovincia: _.find(this.state.provincias, ["provincia",prov]).id});
                            }

                        }
                    }

                    this.props.form.setFieldsValue({
                      latitud: suggest.location.lat,
                      longitud: suggest.location.lng});

                      this.setState({
                        latitud: suggest.location.lat,
                        longitud: suggest.location.lng,
                        flag:true});

                      this.get_franquiciacobertura()


                }
            });
        }
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout =  {labelCol: {xs: { span: 24 },sm: { span: 5 },},wrapperCol: {xs: { span: 24 },sm: { span: 19 },},};
        const formItemLayout1 = {labelCol: {xs: { span: 24 },sm: { span: 0 },},wrapperCol: {xs: { span: 24 },sm: { span: 24 },},};
        return (
          <div>
          {this.state.modal &&
            <Modal
              visible={true}
              confirmLoading={this.state.confirmLoading}
              onOk={this.handleSubmit}
              onCancel={this.props.closeModal}
              cancelText="Cancelar"
              okText="Aceptar"
              width={800}
            >
              <section className="form-v1-container col-md-12">
                               <Form  style={{marginTop:10}}>
                                   <Row gutter={8} >
                                       <Col span={20}>
                                           {getFieldDecorator('latitud', {initialValue:this.state.domicilio ? this.state.domicilio.latitud : ''})(<Input type="hidden"/>)}
                                           {getFieldDecorator('longitud', {initialValue:this.state.domicilio ? this.state.domicilio.longitud : ''})(<Input type="hidden"/>)}
                                           <FormItem  {...formItemLayout} label="Buscar en google">
                                               <Geosuggest
                                                   inputClassName="ant-input"
                                                   country="AR"
                                                   onSuggestSelect={this.onSuggestSelect}
                                                   minLength={4}
                                                   placeholder="Buscar en google maps"
                                                   initialValue={this.state.domicilio && this.state.domicilio.calle + " " + this.state.domicilio.altura +", "+ this.state.domicilio.localidad +", "+ this.state.domicilio.provincia.provincia}
                                               />
                                           </FormItem>
                                       </Col>
                                       <Col span={4}>
                                            <ButtonGroup style={{width:'50%'}}>
                                               { !this.state.OpenMaps &&
                                                 <Button type="primary" icon="environment" loading={this.state.buscando}  onClick={()=> this.setState({buscando:true,OpenMaps:true})}>Geo Posicionar</Button>
                                               }
                                               { this.state.OpenMaps &&
                                                 <Button type="primary"  icon="environment" loading={this.state.buscando} onClick={()=> this.setState({OpenMaps:false,buscando:false})}>Geo Posicionar</Button>
                                               }
                                           </ButtonGroup>
                                       </Col>
                                   </Row>
                                   {this.state.OpenMaps && (this.state.latitud  || this.state.lat)   &&
                                      <Map
                                        flag={this.state.flag}
                                        lat={this.state.latitud ? this.state.latitud : +this.state.lat}
                                        lng={this.state.longitud ? this.state.longitud : +this.state.lng}
                                        onChangePos={(la,lo)=>{
                                           this.props.form.setFieldsValue({
                                             latitud: la,
                                             longitud: lo,});
                                           this.setState({flag:false})
                                           this.get_franquiciacobertura()
                                         }}
                                         leido={(leido)=>{this.setState({buscando:leido,flag:false})}}
                                     />
                                   }
                                   <Divider />
                                   <Row gutter={2} >
                                       <Col span={15}>
                                           <FormItem {...formItemLayout} label="Domicilio" >
                                           {getFieldDecorator('calle', {
                                                   initialValue: this.state.domicilio ? this.state.domicilio.calle : '',
                                                   rules: [{ required: true,message:"Calle es Obligatorio" }],
                                                   })(
                                                   <Input size="default"  placeholder="Calle" />
                                               )}
                                           </FormItem>
                                       </Col>
                                       <Col span={3}>
                                           <FormItem {...formItemLayout1} >
                                           {getFieldDecorator('altura', {
                                               initialValue: this.state.domicilio ? this.state.domicilio.altura : '',
                                               rules: [{ required: true,message:"Altura es Obligatoria" }],
                                               })(
                                               <Input   id="altura" type="number"  placeholder="Altura" />
                                           )}
                                           </FormItem>
                                       </Col>
                                       <Col span={3}>
                                           <FormItem {...formItemLayout1} >
                                           {getFieldDecorator('piso',{
                                             initialValue: this.state.domicilio ? this.state.domicilio.piso : '',
                                           })(
                                               <Input size="default" placeholder="Piso" />
                                           )}
                                           </FormItem>
                                       </Col>
                                       <Col span={3}>
                                           <FormItem {...formItemLayout1} >
                                           {getFieldDecorator('depto',{
                                             initialValue: this.state.domicilio ? this.state.domicilio.depto : '',
                                           })(
                                               <Input size="default" placeholder="Dpto" />
                                           )}
                                           </FormItem>
                                       </Col>
                                   </Row>
                                   <Row gutter={2} >
                                       <Col span={15}>
                                           <FormItem  {...formItemLayout} label="Localidad">
                                           {getFieldDecorator('localidad', {
                                               initialValue: this.state.domicilio ? this.state.domicilio.localidad : '',
                                               rules: [{ required: true,message:"Localidad es Obligatorio"}],
                                           })(
                                               <Input size="default"   placeholder="Localidad"/>
                                           )}
                                           </FormItem>
                                       </Col>
                                       <Col span={4}>
                                           <FormItem  {...formItemLayout1}>
                                           {getFieldDecorator('cp', {

                                               initialValue: this.state.domicilio ? this.state.domicilio.cp : '',
                                               rules: [{ required: true, message:"Códgo Postal es Obligatorio"}],
                                               })(
                                               <Input onChange={this.handleCpChange} size="default" placeholder="Codigo Postal"/>
                                           )}
                                           </FormItem>
                                       </Col>
                                   </Row>
                                   <Row gutter={2} >
                                       <Col span={15}>
                                           <FormItem {...formItemLayout} label="Provincia">
                                           {getFieldDecorator('provincias_id',{
                                               initialValue: this.state.domicilio ? this.state.domicilio.provincias_id : null,
                                               rules: [{ required: true,message:"La Provincia es Obligatorio" }],
                                           })(
                                                   <Select
                                                       placeholder="Provincia"
                                                       style={{width:"100%"}}
                                                       optionFilterProp="children"
                                                       showSearch
                                                       filterOption={(input, option) =>
                                                       option.props.children ? option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : true
                                                         }
                                                       >
                                                   {this.state.provincias && this.state.provincias.map((data,index)=>{
                                                       return <Option value={data.id} key={index}>{data.provincia}</Option>
                                                   })}
                                               </Select>
                                           )}
                                           </FormItem>
                                       </Col>
                                   </Row>
                                   {this.state.convenios &&
                                     <Row gutter={2}>
                                         <Col span={15}>
                                             <FormItem {...formItemLayout} label="Convenio">
                                                 {getFieldDecorator('clientes_convenios_id', {
                                                         initialValue: this.state.domicilio ? this.state.domicilio.clientes_convenios_id : null,
                                                         rules: [{ required: false }],
                                                         })(
                                                           <Select
                                                           style={{width:"100%"}}
                                                           optionFilterProp="children"
                                                           showSearch
                                                           onChange={this.handleSelectChange}
                                                           filterOption={(input, option) =>
                                                             option.props.children ? option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : true
                                                           }
                                                         >
                                                             {this.state.convenios && this.state.convenios.map((data,index)=>{
                                                                 return <Option value={data.id} key={index}>{data.convenio}</Option>
                                                             })}
                                                         </Select>
                                                     )
                                                 }
                                             </FormItem>
                                         </Col>
                                     </Row>
                                   }
                                   <Alert
                                       message={this.state.latitud  && this.state.latitud !==''? 'Domicilio Geo Posicionado' : 'Domicilio NO Geo Posicionado'}
                                       description={this.state.latitud  && this.state.latitud  !==''? '' : 'Busque y seleccione la dirección desde el auto completar del campo  "Buscar de Google:"'}
                                       type={this.state.latitud  && this.state.latitud  !==''? 'success' : 'warning'}
                                       showIcon
                                   />
                                   <Alert
                                       message={this.state.atiende !==''? 'Domicilio Atendido por: '+ this.state.atiende : 'Domicilio NO Validado o sin cobertura'}
                                       type={this.state.atiende !=='' && this.state.NOatiende === ''? 'success' : 'error'}
                                       description={this.state.NOatiende }
                                       showIcon
                                   />
                               </Form>
              </section>
            </Modal>
          }
          {this.state.modal === false &&
            <section className="form-v1-container col-md-12">
                             <Form  style={{marginTop:10}}>
                                 <Row gutter={8} >
                                     <Col span={20}>
                                         <FormItem >{getFieldDecorator('idprovincia', {initialValue:this.state.domicilio ? this.state.domicilio.provincias_id : ''})(<Input type="hidden"/>)}</FormItem>
                                         {getFieldDecorator('latitud', {initialValue:this.state.domicilio ? this.state.domicilio.latitud : ''})(<Input type="hidden"/>)}
                                         {getFieldDecorator('longitud', {initialValue:this.state.domicilio ? this.state.domicilio.longitud : ''})(<Input type="hidden"/>)}
                                         <FormItem  {...formItemLayout} label="Buscar en google">
                                         
                                             <Geosuggest
                                                 inputClassName="ant-input"
                                                 country="AR"
                                                 onSuggestSelect={this.onSuggestSelect}
                                                 minLength={4}
                                                 placeholder="Buscar en google maps"
                                                 initialValue={this.state.domicilio && this.state.domicilio.id && this.state.domicilio.calle + " " + this.state.domicilio.altura +", "+ this.state.domicilio.localidad +", "+ this.state.domicilio.provincia.provincia}
                                             />
                                             
                                         </FormItem>
                                     </Col>
                                     <Col span={4}>
                                        <ButtonGroup style={{width:'60%', marginTop:10}}>
                                             {!this.state.OpenMaps &&
                                               <Button type="primary" icon="environment" loading={this.state.buscando}  onClick={()=> this.setState({buscando:true,OpenMaps:true})}>Geo Posicionar</Button>
                                             }
                                             {this.state.OpenMaps &&
                                               <Button type="primary"  icon="environment" loading={this.state.buscando} onClick={()=> this.setState({OpenMaps:false,buscando:false})}>Geo Posicionar</Button>
                                             }
                                         </ButtonGroup>
                                     </Col>
                                 </Row>
                                 {this.state.OpenMaps && (this.state.latitud  || this.state.lat)   &&
                                    <Map
                                      lat={this.state.latitud ? this.state.latitud : +this.state.lat}
                                      lng={this.state.longitud ? this.state.longitud : +this.state.lng}
                                      flag={this.state.flag}
                                      onChangePos={(la,lo)=>{
                                         this.props.form.setFieldsValue({
                                           latitud: la,
                                           longitud: lo});
                                         this.setState({flag:false})
                                         this.get_franquiciacobertura()
                                      }}
                                      leido={(leido)=>{this.setState({buscando:leido,falag:false})}}
                                   />
                                 }
                                 <Divider />
                                 <Row gutter={2} >
                                     <Col span={15}>
                                         <FormItem {...formItemLayout} label="Domicilio" >
                                         {getFieldDecorator('calle', {
                                                 initialValue: this.state.domicilio ? this.state.domicilio.calle : '',
                                                 rules: [{ required: true, message: ' ' }],
                                                 })(
                                                 <Input size="default"  placeholder="Calle" />
                                             )}
                                         </FormItem>
                                     </Col>
                                     <Col span={3}>
                                         <FormItem {...formItemLayout1} >
                                         {getFieldDecorator('altura', {
                                             initialValue: this.state.domicilio ? this.state.domicilio.altura : '',
                                             rules: [{ required: true, message: ' ' }],
                                             })(
                                             <Input   id="altura" type="number"  placeholder="Altura" />
                                         )}
                                         </FormItem>
                                     </Col>
                                     <Col span={3}>
                                         <FormItem {...formItemLayout1} >
                                         {getFieldDecorator('piso',{
                                           initialValue: this.state.domicilio ? this.state.domicilio.piso : '',
                                         })(
                                             <Input size="default" placeholder="Piso" />
                                         )}
                                         </FormItem>
                                     </Col>
                                     <Col span={3}>
                                         <FormItem {...formItemLayout1} >
                                         {getFieldDecorator('depto',{
                                           initialValue: this.state.domicilio ? this.state.domicilio.depto : '',
                                         })(
                                             <Input size="default" placeholder="Dpto" />
                                         )}
                                         </FormItem>
                                     </Col>
                                 </Row>
                                 <Row gutter={2} >
                                     <Col span={15}>
                                         <FormItem  {...formItemLayout} label="Localidad">
                                         {getFieldDecorator('localidad', {
                                             initialValue: this.state.domicilio ? this.state.domicilio.localidad : '',
                                             rules: [{ required: true, message: ' '}],
                                         })(
                                             <Input size="default"   placeholder="Localidad"/>
                                         )}
                                         </FormItem>
                                     </Col>
                                     <Col span={4}>
                                         <FormItem  {...formItemLayout1}>
                                         {getFieldDecorator('cp', {
                                             initialValue: this.state.domicilio ? this.state.domicilio.cp : '',
                                             rules: [{ required: true, message: ' '}],
                                             })(
                                             <Input size="default" placeholder="Codigo Postal"/>
                                         )}
                                         </FormItem>
                                     </Col>
                                 </Row>
                                 <Row gutter={2} >
                                     <Col span={15}>
                                         <FormItem {...formItemLayout} label="Provincia">
                                         {getFieldDecorator('provincias_id',{
                                             initialValue: this.state.domicilio ? this.state.domicilio.provincias_id : '',
                                             rules: [{ required: true, message: ' ' }],
                                         })(
                                                 <Select
                                                     placeholder="Provincia"
                                                     style={{width:"100%"}}
                                                     optionFilterProp="children"
                                                     showSearch
                                                     filterOption={(input, option) =>
                                                     option.props.children ? option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : true
                                                       }
                                                     >
                                                 {this.state.provincias && this.state.provincias.map((data,index)=>{
                                                     return <Option value={data.id} key={index}>{data.provincia}</Option>
                                                 })}
                                             </Select>
                                         )}
                                         </FormItem>
                                     </Col>
                                 </Row>
                                 {this.state.convenios &&
                                   <Row gutter={2}>
                                       <Col span={15}>
                                           <FormItem {...formItemLayout} label="Convenio">
                                               {getFieldDecorator('clientes_convenios_id', {
                                                       initialValue: this.state.domicilio ? this.state.domicilio.clientes_convenios_id : null,
                                                       rules: [{ required: false, message: ' ' }],
                                                       })(
                                                         <Select
                                                         style={{width:"100%"}}
                                                         optionFilterProp="children"
                                                         showSearch
                                                         filterOption={(input, option) =>
                                                           option.props.children ? option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : true
                                                         }
                                                       >
                                                           {this.state.convenios && this.state.convenios.map((data,index)=>{
                                                               return <Option value={data.id} key={index}>{data.convenio}</Option>
                                                           })}
                                                       </Select>
                                                   )
                                               }
                                           </FormItem>
                                       </Col>
                                   </Row>
                                 }
                                 <Alert
                                     message={this.state.latitud  && this.state.latitud !==''? 'Domicilio Geo Posicionado' : 'Domicilio NO Geo Posicionado'}
                                     description={this.state.latitud  && this.state.latitud  !==''? '' : 'Busque y seleccione la dirección desde el auto completar del campo  "Buscar de Google:"'}
                                     type={this.state.latitud  && this.state.latitud  !==''? 'success' : 'warning'}
                                     showIcon
                                 />
                                 <Alert
                                     message={this.state.atiende !==''? 'Domicilio Atendido por: '+ this.state.atiende : 'Domicilio NO Validado o sin cobertura'}
                                     type={this.state.atiende !=='' && this.state.NOatiende === ''? 'success' : 'error'}
                                     description={this.state.NOatiende }
                                     showIcon
                                 />
                             </Form>
            </section>
          }
          </div>
        );
    }
}
const mapStateToProps = (state) => ({
    user: state.user,
});

const WrappedNuevo = Form.create()(NuevoDomicilio);

export default connect(
  mapStateToProps,
)(WrappedNuevo);
