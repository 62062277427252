import React from 'react';
import {api} from '../../api';
import { connect } from 'react-redux';
import { message, Modal, Form, Input } from 'antd';
import '../../styles.scss'

const FormItem = Form.Item;

class NuevoContacto extends React.Component {

    state = {
        confirmLoading: false,
    }

    async componentDidMount(){
        try {

        } catch (e) {
            message.error(e.toString(), 7);
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    this.setState({
                        confirmLoading: true,
                    })
                    if(this.props.destinatario === 'existe'){
                        const response = await api.clientes_contactos.create({
                            ...values,
                            clientes_id: this.props.idcliente,
                        });
                        if (response.status === "success") {
                            message.success("Creado")
                        }else {
                            message.error(response.message, 7);
                        }
                    }else if(this.props.destinatario === ''){
                        this.props.cargarDatos({
                            contacto: values.contacto,
                            telefono: values.telefono,
                            celular: values.celular,
                            mail: values.mail,
                          });
                        //   this.props.closeModal();
                    }
                } catch (e) {
                    message.error(e.toString(), 7);
                } finally {
                    this.setState({
                        confirmLoading: false,
                    })
                    // this.props.onCreate();
                    // this.props.closeModal();
                }
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: { span: 5 },
            wrapperCol: { span: 19 },
        };
        return (
            <Modal
              visible={true}
              confirmLoading={this.state.confirmLoading}
              onOk={this.handleSubmit}
              onCancel={this.props.closeModal}
              cancelText="Cancelar"
              okText="Crear"
              width={500}
            >
                <section className="form-v1-container col-md-12">
                    <h4 style={{marginBottom:15}}>Nuevo contacto</h4>
                    <Form layout="inline">
                        <FormItem label="Contacto" {...formItemLayout} style={{width:"100%"}}>
                            {getFieldDecorator('contacto', {
                                initialValue: this.state.contacto ? this.state.contacto : '',
                                rules: [{ required: true, message: 'El campo es obligatorio.' }],
                                })(
                                <Input size="default" />
                            )}
                        </FormItem>

                        <FormItem label="Telefono" {...{
                            labelCol: { span: 5 },
                            wrapperCol: { span: 10 },
                        }} style={{width:"100%"}}>
                            {getFieldDecorator('telefono', {
                                initialValue: this.state.telefono ? this.state.telefono : '',
                                rules: [{ required: false, message: 'El campo es obligatorio.' }],
                                })(
                                <Input size="default" />
                            )}
                        </FormItem>

                        <FormItem label="Celular" {...{
                            labelCol: { span: 5 },
                            wrapperCol: { span: 10 },
                        }} style={{width:"100%"}}>
                            {getFieldDecorator('celular', {
                                initialValue: this.state.celular ? this.state.celular : '',
                                rules: [{ required: false, message: 'El campo es obligatorio.' }],
                                })(
                                <Input size="default" />
                            )}
                        </FormItem>

                        <FormItem label="Email" {...formItemLayout} style={{width:"100%"}}>
                            {getFieldDecorator('mail', {
                                initialValue: this.state.mail ? this.state.mail : '',
                                rules: [{ required: false, message: 'El campo es obligatorio.' }],
                                })(
                                <Input size="default" />
                            )}
                        </FormItem>
                    </Form>
                </section>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
});

const WrappedNuevo = Form.create()(NuevoContacto);

export default connect(
  mapStateToProps,
)(WrappedNuevo);
