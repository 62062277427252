import React from 'react';
import {api} from './api';
import { connect } from 'react-redux';
import { message, Steps, Modal, Form, Input, Alert, Divider, InputNumber, Select, Row, Col, Button, Icon } from 'antd';
import Map from './components/domicilios/Map';
import Geosuggest from 'react-geosuggest';
import queryString from 'query-string';
import _ from 'lodash'
import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
const FormItem = Form.Item;
const Option = Select.Option;
const { Step } = Steps;
const ButtonGroup = Button.Group;

class Nuevo extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            confirmLoading: false,
            loading: false,
            paso:1,
            NOatiende:'',
            atiende:"",
        }
    }

    async componentDidMount(){
        try {
            //Condiciones IVA
            const responseCondicionesIva = await api.condiciones_iva.getAll(queryString.stringify({
                limit: 50,
                page: 1,
            }));
            if (responseCondicionesIva.status === "success") {
                this.setState({
                    condiciones_iva: responseCondicionesIva.data.condicioniva,
                })
            }
            //Documentos Tipos
            const responseDocumentosTipos = await api.documentos_tipos.getAll(queryString.stringify({
                limit: 50,
                page: 1,
            }))
            if (responseDocumentosTipos.status === "success") {
                this.setState({
                    documentos_tipos: responseDocumentosTipos.data.documentotipos,
                })
            }
            //Provincias
            const responseProvincias = await api.provincias.getAll(queryString.stringify({
                limit: 50,
                page: 1,
            }))
            if (responseProvincias.status === "success") {
                this.setState({
                    provincias: responseProvincias.data.provincias,
                })
            }

        } catch (e) {
            message.error(e.toString(), 7);
        }
    }

   handleSubmit = (e) => {
        e.preventDefault();
        if(this.state.paso===1){
            this.setState({paso:2})
        }
        if(this.state.paso===2){
              this.setState({paso:3})
        }
        if(this.state.paso===3){
          this.props.form.validateFields(async (err, values) => {
            if (!err) {
              try {
                  this.setState({
                      confirmLoading: true,
                  })
                  const response = await api.clientes.create({
                      ...values,
                      documento: values.documento.replace(/-/gi, ''),
                      contactos:[{
                          contacto: values.contacto,
                          telefono: ""+values.telefono,
                          mail: values.mail,
                          celular: ""+values.celular,
                      }],
                      domicilios:[{
                          calle: values.calle,
                          altura: ""+values.altura,
                          piso: values.piso,
                          depto: values.depto,
                          localidad: values.localidad,
                          cp: values.cp,
                          provincias_id: values.provincias_id,
                          domiciliotipos_id: 1,
                          latitud: this.state.latitud !== null ? this.state.latitud.toString() : '', // esta con state por que no exite el input
                          longitud: this.state.longitud !== null ? this.state.longitud.toString() : '', // esta con state por que no exite el input
                      }]
                  });
                  if (response.status === "success") {
                      this.props.onCreate();
                      this.props.closeModal();
                  }else {
                      message.error(response.message, 7);
                  }
              } catch (e) {
                  message.error(e.toString(), 7);
              } finally {
                  this.setState({
                      confirmLoading: false,
                  })
              }
            }else{
              message.error("Atención verifique los campos no completados marcados en rojo", 7);
            }

          });
        }
    }
   consultarCuit = async (e, event = '') => {
      if((e.keyCode === 13 || event === 'click')){
        const formatCuit = this.props.form.getFieldValue("documento").replace(/[^\d]/g, '');
        if(this.props.form.getFieldValue("documento")!== formatCuit){
          message.error("Error en el formato del dato ingresado", 7);
        }
        if (this.props.form.getFieldValue("documentotipos_id") === 1){
          this.setState({
              loading:true,
              latitud: null,
              longitud: null,
          })

          this.props.form.setFieldsValue({
              cliente: '',
              razonsocial: '',
              provincias_id: null,
              calle: '',
              altura: '',
              piso: '',
              depto: '',
              cp: '',
              estado: '',
              localidad: '',
              condicioniva_id: null,
          });

          try {
              if (this.validarCuit(this.props.form.getFieldValue("documento"))) {
                  const formatCuit = this.props.form.getFieldValue("documento").replace(/-/gi, '');
                  const response = await api.afip.consultarCuit(formatCuit);

                  const personaReturn = JSON.parse(response.data);


                  if (personaReturn.errorConstancia) {
                      message.error(personaReturn.errorConstancia.error, 7);
                      this.setState({loading:false,})
                      return;
                  }
                  if (personaReturn.error) {
                      message.error(personaReturn.error, 7);
                      this.setState({loading:false,})
                      return;
                  }
                  //Provincia
                  const provincia = _.find(this.state.provincias, {codigoafip: parseInt(personaReturn.idProvincia)});
                  //Cliente
                  const cliente = personaReturn.nombre;
                  //Localidad
                  const localidad = personaReturn.localidad;

                  const condicion_iva = _.find(this.state.condiciones_iva, {codigoafip: ""+personaReturn.codigoiva});
                  

                  let direccion = personaReturn.direccion + ', ' + localidad + ', ' +(provincia ? provincia.provincia:'')

                  this.setState({direccion: direccion,});
                  this.handleSelect('Argentina, '+ provincia.provincia + ', ' +localidad +', '+ personaReturn.direccion)

                  this.props.form.setFieldsValue({
                      cliente: cliente,
                      razonsocial: cliente,
                      provincias_id: provincia ? provincia.id : '',
                      calle: personaReturn.direccion,
                      cp: personaReturn.codPostal,
                      estado: personaReturn.estadoClave,
                      localidad: localidad,
                      condicioniva_id: condicion_iva ? condicion_iva.id : null,
                  });

              }
          } catch (e) {
              message.error(e.toString(), 7);
          }
          this.setState({loading:false,})
      }
    }
   }
   async get_franquiciacobertura (){
       try {
           const responseFranquicia = await api.franquicias.getAtiende(this.state.longitud,this.state.latitud,this.state.cp);
           if (responseFranquicia.status === "success" ) {
               if(responseFranquicia.data.franquicia.id !== 0){
                   this.setState({atiende:responseFranquicia.data.franquicia.codigo+"-"+ responseFranquicia.data.franquicia.franquicia,NOatiende:""})
                   this.get_franquiciaNOcobertura()
               }else{
                   this.setState({atiende:"",NOatiende:""})
               }
           }

       } catch (e) {
           message.error(e.toString(), 7);
       }

   }
   async get_franquiciaNOcobertura (){
       try {
           const responseFranquicia = await api.franquicias.getNoAtiende(this.state.longitud,this.state.latitud,this.state.cp);
           if (responseFranquicia.status === "success" ) {
               if(responseFranquicia.data.franquicia_zona.id !== 0){
                   this.setState({NOatiende:"Domicilio excluido de ENTREGA o RETIRO a domicilio. " + responseFranquicia.data.franquicia_zona.zona})
               }else{
                   this.setState({NOatiende:""})
               }

           }

       } catch (e) {
           message.error(e.toString(), 7);
       }

   }
   //CUIT AFIP
 
    getDigits = (cuit) => {
        const digits = [];

        for (let i = 0; i < cuit.length; i++) {
            digits.push(Number.parseInt(cuit[i]));
        }

        return digits;
    }
    getSumProd = (digits) => {
        let sumProd = 0;

        for (let i = 0; i < [5, 4, 3, 2, 7, 6, 5, 4, 3, 2].length; i++) {
            sumProd = sumProd + (([5, 4, 3, 2, 7, 6, 5, 4, 3, 2][i] * digits[i]));
        }

        return sumProd;
    }
    validarCuit = (cuit) => {
        // Regex for cuit with dash ^\d{2}\-\d{8}\-\d{1}$
        // Regex for cuit without dash ^\d{11}$
        // eslint-disable-next-line
        if (new RegExp(/^\d{2}\-\d{8}\-\d{1}$/).test(cuit) || new RegExp(/^\d{11}$/).test(cuit)) {
            // eslint-disable-next-line
            const formatCuit = cuit.replace(/-/gi, '');

            const DIGITS = this.getDigits(formatCuit);
            const lastDigit = DIGITS[10];

            const verificationResult1 = this.getSumProd(DIGITS);

            const verificationResult2 = Math.floor(verificationResult1 / 11);

            const verificationResult3 = verificationResult1 - (verificationResult2 * 11);

            let verificador = 0;

            if (verificationResult3 !== 0) {
                verificador = 11 - verificationResult3;
            }

            return verificador === lastDigit;
        } else {
            message.error("Formato de CUIT invalido.", 5);
        }
    }
    onSuggestSelect = (suggest) => {
        if (suggest && suggest.gmaps) {
            this.setState({
                localidad: null,
                calle: null,
                altura: null,
                cp: null,
                latitud: null,
                longitud: null,
                provincias_id: null,
                domiciliotipos_id: 2,
            }, () => {
                let localidad="";
                if (suggest && suggest.gmaps && suggest.gmaps.address_components) {
                    for (var i = 0; i < suggest.gmaps.address_components.length; i++) {
                        if (suggest.gmaps.address_components[i].types.indexOf('route') !== -1) {
                            this.props.form.setFieldsValue({calle: suggest.gmaps.address_components[i].long_name})
                        }
                        if (suggest.gmaps.address_components[i].types.indexOf('locality') !== -1) {
                            this.props.form.setFieldsValue({localidad: suggest.gmaps.address_components[i].long_name})
                            localidad=suggest.gmaps.address_components[i].long_name;
                        }
                        if (suggest.gmaps.address_components[i].types.indexOf('sublocality') !== -1) {
                            this.props.form.setFieldsValue({localidad: (localidad !==""?localidad:"CABA -") + suggest.gmaps.address_components[i].long_name})
                        }
                        if (suggest.gmaps.address_components[i].types.indexOf('postal_code') !== -1) {
                            this.props.form.setFieldsValue({cp: suggest.gmaps.address_components[i].long_name})
                        }
                        if (suggest.gmaps.address_components[i].types.indexOf('street_number') !== -1) {
                            this.props.form.setFieldsValue({altura: suggest.gmaps.address_components[i].long_name})
                        }
                        if (suggest.gmaps.address_components[i].types.indexOf('administrative_area_level_1') !== -1) {

                            if(suggest.gmaps.address_components[i].short_name==="CABA"){
                                this.props.form.setFieldsValue({provincias_id: _.find(this.state.provincias, ["provincia","Ciudad Autónoma de Buenos Aires"]).id});
                            }else{
                              let prov = _.deburr(suggest.gmaps.address_components[i].short_name)
                              this.props.form.setFieldsValue({provincias_id: _.find(this.state.provincias, ["provincia",prov]).id});
                              this.props.form.setFieldsValue({idprovincia: _.find(this.state.provincias, ["provincia",prov]).id});
                            }
                        }
                    }

                    this.props.form.setFieldsValue({latitud: suggest.location.lat, longitud: suggest.location.lng});
                    // eslint-disable-next-line
                    this.state.latitud = suggest.location.lat
                    // eslint-disable-next-line
                    this.state.longitud = suggest.location.lng
                    // eslint-disable-next-line
                    this.state.cp = this.props.form.cp
                    // eslint-disable-next-line
                    this.state.urlmap=suggest.gmaps.url
                    // eslint-disable-next-line
                    this.state.atiende=""
                    this.get_franquiciacobertura()
                }
            });
        }
    }
    handleSelect = async (address) => {
      var results = await geocodeByAddress(address);

      if(results.length===1){
          this.setState({direccion:results[0].formatted_address});
          for (var i = 0; i < results[0].address_components.length; i++) {
            if (results[0].address_components[i].types.indexOf('route') !== -1) {
                this.props.form.setFieldsValue({calle: results[0].address_components[i].long_name})
            }
            if (results[0].address_components[i].types.indexOf('locality') !== -1) {
                this.props.form.setFieldsValue({localidad: results[0].address_components[i].long_name})
            }
            if (results[0].address_components[i].types.indexOf('postal_code') !== -1) {
                this.props.form.setFieldsValue({cp: results[0].address_components[i].long_name})
            }
            if (results[0].address_components[i].types.indexOf('street_number') !== -1) {
                this.props.form.setFieldsValue({altura: results[0].address_components[i].long_name})
            }
            if (results[0].address_components[i].types.indexOf('administrative_area_level_1') !== -1) {
                if(results[0].address_components[i].short_name==="CABA"){
                    if(_.find(this.state.provincias, ["provincia","Ciudad Autónoma de Buenos Aires"])){
                      this.props.form.setFieldsValue({provincias_id: _.find(this.state.provincias, ["provincia","Ciudad Autónoma de Buenos Aires"]).id});
                    }
                }else{
                  let prov = _.deburr(results[0].address_components[i].short_name)
                  this.props.form.setFieldsValue({provincias_id: _.find(this.state.provincias, ["provincia",prov]).id});
                  this.props.form.setFieldsValue({idprovincia: _.find(this.state.provincias, ["provincia",prov]).id});
                }
            }
          }

          var latLng= await getLatLng(results[0])
          if (latLng){
              this.setState({latitud: latLng.lat, longitud: latLng.lng})
              this.get_franquiciacobertura()
          }
      }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {labelCol: {sm: { span: 5 },},wrapperCol: {sm: { span: 19 },},};
        const formItemLayout1 = {labelCol: {xs: { span: 24 },sm: { span: 0 },},wrapperCol: {xs: { span: 24 },sm: { span: 24 },},};

        return (
            <Modal
              visible={true}
              confirmLoading={this.state.confirmLoading}
              onOk={this.handleSubmit}
              onCancel={this.props.closeModal}
              cancelText="Cancelar"
              okText={this.state.paso === 1 ? "Siguiente":(this.state.paso === 2 ? "Siguiente":"Finalizar")}
              width={900}
            >
                <section className="form-v1-container col-md-12">
                    <h4 style={{marginBottom:15}}>Nuevo cliente</h4>
                    <Steps current={this.state.paso-1}>
                      <Step title="Cliente" description=""  onClick={() => this.setState({paso:1})} />
                      <Step title="Domicilio" description=""  onClick={() => this.setState({paso:2})} />
                      <Step title="Contacto y Finalizar" description=""   onClick={() => this.setState({paso:3})}/>
                    </Steps>
                    <div className="steps-content">
                        <Form autoComplete="off" style={{display:this.state.paso===1?"block":"none"}}>
                            <Row gutter={8}>
                                <Col span={10}>
                                    <FormItem label="Documento" {...{
                                        labelCol: {
                                            xs: { span: 24 },
                                            sm: { span: 12 },
                                        },
                                        wrapperCol: {
                                            xs: { span: 24 },
                                            sm: { span: 12 },
                                        },
                                    }}>
                                        {getFieldDecorator('documentotipos_id',{
                                            initialValue: 1,
                                            rules: [{ required: true, message: ' '}],
                                        })(
                                            <Select
                                              placeholder="Tipo"
                                              optionFilterProp="children"
                                              showSearch
                                              filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                              }
                                            >
                                                {this.state.documentos_tipos && this.state.documentos_tipos.map((data,index)=>{
                                                    return <Option value={data.id} key={index}>{data.documentotipo}</Option>
                                                })}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>
                                <Col span={8}>
                                    <FormItem {...{
                                        labelCol: {
                                            xs: { span: 24 },
                                            sm: { span: 0 },
                                        },
                                        wrapperCol: {
                                            xs: { span: 24 },
                                            sm: { span: 24 },
                                        },
                                    }}>
                                        {getFieldDecorator('documento', {
                                            rules: [{ required: true, message: ' ' }],
                                            initialValue: this.state.documento ? this.state.documento : '',
                                            })(
                                            <Input size="default" placeholder="Número..." autoFocus addonBefore={<Icon type="search" onClick={(e)=>this.consultarCuit(e, 'click')} />} onKeyDown={(e)=>this.consultarCuit(e)}/>
                                        )}
                                    </FormItem>
                                </Col>
                                <Col span={6}>
                                    <FormItem {...{
                                        labelCol: {
                                            xs: { span: 24 },
                                            sm: { span: 0 },
                                        },
                                        wrapperCol: {
                                            xs: { span: 24 },
                                            sm: { span: 24 },
                                        },
                                    }}>
                                        {getFieldDecorator('estado', {
                                            initialValue: this.state.estado ? this.state.estado : '',
                                            })(
                                            <Input size="default" disabled />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col span={24}>
                                    <FormItem label="Razón Social" {...{
                                        labelCol: {
                                            xs: { span: 24 },
                                            sm: { span: 5 },
                                        },
                                        wrapperCol: {
                                            xs: { span: 24 },
                                            sm: { span: 19 },
                                        },
                                    }}>
                                        {getFieldDecorator('razonsocial', {
                                            rules: [{ required: true, message: ' ' }],
                                            })(
                                            <Input size="default" placeholder="Razón Social" />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col span={24}>
                                    <FormItem label="Cliente" {...{
                                        labelCol: {
                                            xs: { span: 24 },
                                            sm: { span: 5 },
                                        },
                                        wrapperCol: {
                                            xs: { span: 24 },
                                            sm: { span: 19 },
                                        },
                                    }}>
                                        {getFieldDecorator('cliente', {
                                            rules: [{ required: true, message: ' ' }],
                                            })(
                                            <Input size="default" placeholder="Cliente" />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col span={24}>
                                    <FormItem label="Condición IVA" {...{
                                        labelCol: {
                                            xs: { span: 24 },
                                            sm: { span: 5 },
                                        },
                                        wrapperCol: {
                                            xs: { span: 24 },
                                            sm: { span: 12 },
                                        },
                                    }}>
                                    {getFieldDecorator('condicioniva_id', {
                                        rules: [{ required: true, message: ' ' }],
                                        })(
                                          <Select
                                            placeholder="Condición de IVA"
                                            optionFilterProp="children"
                                            showSearch
                                            filterOption={(input, option) =>
                                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                          >
                                                {this.state.condiciones_iva && this.state.condiciones_iva.map((data,index)=>{
                                                    return <Option value={data.id} key={index}>{data.condicion}</Option>
                                                })}
                                            </Select>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                        </Form>
                        <Form autoComplete="off" style={{display:this.state.paso===2?"block":"none"}}>
                            <Row gutter={16} >
                                {/* <Col span={24}> */}
                                    {getFieldDecorator('latitud', {})(<Input type="hidden"/>)}
                                    {getFieldDecorator('longitud', {})(<Input type="hidden"/>)}
                                    <FormItem label="Buscar en google" {...{
                                                    labelCol: {sm: { span: 5 },},
                                                    wrapperCol: {sm: { span: 19 },},
                                                }}>
                                      <ButtonGroup >
                                            <Col span={16}>
                                            <Geosuggest
                                              inputClassName="ant-input"
                                              country="AR"
                                              onSuggestSelect={this.onSuggestSelect}
                                              minLength={4}
                                              placeholder="Buscar en Google Maps"
                                            />
                                            </Col>
                                            <Col span={8}>
                                            { !this.state.OpenMaps &&
                                                <Button type="primary" icon="environment" loading={this.state.buscando}  onClick={()=> this.setState({buscando:true,OpenMaps:true})}>Geo Posicionar</Button>
                                            }
                                            { this.state.OpenMaps &&
                                                <Button type="primary"  icon="environment" loading={this.state.buscando} onClick={()=> this.setState({OpenMaps:false,buscando:false})}>Geo Posicionar</Button>
                                            }
                                            </Col>
                                      </ButtonGroup>
                                    </FormItem>
                                {/* </Col> */}

                            </Row>
                            {this.state.OpenMaps && (this.state.latitud  || this.state.lat)   &&
                               <Map
                                lat={this.state.latitud  ? +this.state.latitud:+this.state.lat}
                                lng={this.state.longitud ? +this.state.longitud:+this.state.lng}
                                onChangePos={(la,lo)=>{
                                  // eslint-disable-next-line
                                  this.state.latitud=la;
                                  // eslint-disable-next-line
                                  this.state.longitud=lo;
                                }}
                                leido={(leido)=>{this.setState({buscando:leido})}}
                              />
                            }
                            <Divider />
                            <Row gutter={2} >
                                <Col span={15}>
                                    <FormItem {...formItemLayout} label="Domicilio" >
                                    {getFieldDecorator('calle', {
                                            rules: [{ required: true, message: ' ' }],
                                            })(
                                            <Input size="default"  placeholder="Calle" />
                                        )}
                                    </FormItem>
                                </Col>
                                <Col span={3}>
                                    <FormItem {...formItemLayout1} >
                                    {getFieldDecorator('altura', {
                                        rules: [{ required: true, message: ' ' }],
                                        })(
                                        <InputNumber id="altura" placeholder="Altura" />
                                    )}
                                    </FormItem>
                                </Col>
                                <Col span={3}>
                                    <FormItem {...formItemLayout1} >
                                    {getFieldDecorator('piso',{})(
                                        <Input size="default" placeholder="Piso" />
                                    )}
                                    </FormItem>
                                </Col>
                                <Col span={3}>
                                    <FormItem {...formItemLayout1} >
                                    {getFieldDecorator('depto',{})(
                                        <Input size="default" placeholder="Dpto" />
                                    )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={2} >
                                <Col span={15}>
                                    <FormItem  {...formItemLayout} label="Localidad">
                                    {getFieldDecorator('localidad', {
                                        rules: [{ required: true, message: ' '}],
                                    })(
                                        <Input size="default"   placeholder="Localidad"/>
                                    )}
                                    </FormItem>
                                </Col>
                                <Col span={4}>
                                <FormItem  {...formItemLayout1}>
                                    {getFieldDecorator('cp', {
                                        rules: [{ required: true, message: ' '}],
                                        })(
                                        <Input size="default" placeholder="Código Postal"/>
                                    )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={2} >
                                <Col span={15}>
                                    <FormItem {...formItemLayout} label="Provincia">
                                    {getFieldDecorator('provincias_id',{
                                        rules: [{ required: true, message: ' ' }],
                                    })(
                                        <Select
                                          placeholder="Provincia"
                                          optionFilterProp="children"
                                          showSearch
                                          filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                          }
                                        >
                                            {this.state.provincias && this.state.provincias.map((data,index)=>{
                                                return <Option value={data.id} key={index}>{data.provincia}</Option>
                                            })}
                                        </Select>
                                    )}
                                    </FormItem>
                                </Col>
                            </Row>
                            {this.state.convenios &&
                              <Row gutter={2}>
                                  <Col span={15}>
                                      <FormItem {...formItemLayout} label="Convenio">
                                          {getFieldDecorator('clientes_convenios_id', {
                                                  initialValue: this.state.clientes_convenios_id ? this.state.clientes_convenios_id : '',
                                                  rules: [{ required: true, message: ' ' }],
                                                  })(
                                                    <Select
                                                      placeholder="Convenios"
                                                      optionFilterProp="children"
                                                      showSearch
                                                      filterOption={(input, option) =>
                                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                      }
                                                    >
                                                      {this.state.convenios && this.state.convenios.map((data,index)=>{
                                                          return <Option value={data.id} key={index}>{data.convenio}</Option>
                                                      })}
                                                  </Select>
                                              )
                                          }
                                      </FormItem>
                                  </Col>
                              </Row>
                            }

                            <Alert
                                message={this.state.latitud  && this.state.latitud !==''? 'Domicilio Geo Posicionado' : 'Domicilio NO Geo Posicionado'}
                                description={this.state.latitud  && this.state.latitud  !==''? '' : 'Busque y seleccione la dirección desde el auto completar del campo  "Buscar de Google:"'}
                                type={this.state.latitud  && this.state.latitud  !==''? 'success' : 'warning'}
                                showIcon
                            />
                            <Alert
                                message={this.state.atiende !==''? 'Domicilio Atendido por: '+ this.state.atiende : 'Domicilio NO Validado o sin cobertura'}
                                type={this.state.atiende !=='' && this.state.NOatiende === ''? 'success' : 'error'}
                                description={this.state.NOatiende }
                                showIcon
                            />
                        </Form>
                        <Form autoComplete="off" style={{display:this.state.paso===3?"block":"none"}}>
                            <Row gutter={8} >
                                <Col span={20}>
                                    <FormItem {...{
                                        labelCol: {
                                            xs: { span: 24 },
                                            sm: { span: 6 },
                                        },
                                        wrapperCol: {
                                            xs: { span: 24 },
                                            sm: { span: 16 },
                                        },
                                    }} label="Contacto">
                                        {getFieldDecorator('contacto', {
                                            initialValue: this.state.localidad ? this.state.localidad : '',
                                            rules: [{ required: true,  message: ' ' }],
                                        })(
                                            <Input size="default" placeholder="Contacto"/>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={8} >
                                <Col span={12}>
                                    <FormItem label="Teléfono / Celular" {...{
                                        labelCol: {
                                            xs: { span: 24 },
                                            sm: { span: 10 },
                                        },
                                        wrapperCol: {
                                            xs: { span: 24 },
                                            sm: { span: 14 },
                                        },
                                    }} >
                                        {getFieldDecorator('telefono', {
                                            initialValue: this.state.telefono ? this.state.telefono : '',
                                            rules: [{ required: true, message: ' '}],
                                            })(
                                            <InputNumber style={{width:"100%"}} placeholder="Teléfono"/>
                                        )}
                                    </FormItem>
                                </Col>

                                <Col span={12}>
                                    <FormItem {...{
                                        labelCol: {
                                            xs: { span: 24 },
                                            sm: { span: 0 },
                                        },
                                        wrapperCol: {
                                            xs: { span: 24 },
                                            sm: { span: 14 },
                                        },
                                    }} >
                                        {getFieldDecorator('celular', {
                                            initialValue: this.state.celular ? this.state.celular : '',
                                            })(
                                            <InputNumber style={{width:"100%"}} size="default" placeholder="Celular"/>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={8} >
                                <Col span={20}>
                                    <FormItem label="Email" {...{
                                        labelCol: {
                                            xs: { span: 24 },
                                            sm: { span: 6 },
                                        },
                                        wrapperCol: {
                                            xs: { span: 24 },
                                            sm: { span: 16 },
                                        },
                                    }} >
                                        {getFieldDecorator('mail', {
                                            initialValue: this.state.mail ? this.state.mail : '',
                                            rules: [{ required: true, message: ' '}],
                                            })(
                                            <Input size="default" placeholder="Email"/>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row gutter={8} >
                                <Col span={24}>
                                    <FormItem label="Comentario" {...{
                                        labelCol: {
                                            xs: { span: 24 },
                                            sm: { span: 5 },
                                        },
                                        wrapperCol: {
                                            xs: { span: 24 },
                                            sm: { span: 19 },
                                        },
                                    }} >
                                        {getFieldDecorator('comentario', {
                                            initialValue: this.state.comentario ? this.state.comentario : '',
                                            })(
                                            <Input.TextArea size="default" placeholder="Comentario..."/>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                        </Form>
                    </div>

                </section>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
});

const WrappedNuevo = Form.create()(Nuevo);

export default connect(
  mapStateToProps,
)(WrappedNuevo);
