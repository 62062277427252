import React from 'react';
import {api} from '../../api';
import { connect } from 'react-redux';
import { message, Modal, Form, Input, Select, Row, Col,Divider, Alert } from 'antd';
import Geosuggest from 'react-geosuggest';
import './../../styles.scss';
import './styles.scss';
import _ from 'lodash';
import queryString from 'query-string';

const FormItem = Form.Item;
const Option = Select.Option;

class NuevoDomicilio extends React.Component {

    cambio = (e) =>{
        this.setState({
            latitud: '',
            atiende: '',
            NOatiende:'',
        })
        this.props.form.setFieldsValue({latitud: '', longitud: ''});
    }

    state = {
        disabledLogin: false,
        confirmLoading: false,
        urlmap:"https://maps.google.com.ar",
        atiende:"",
        NOatiende:'',
    }

    async componentDidMount(){
        try {
            //Provincias
            const responseProvincias = await api.provincias.getAll();
            if (responseProvincias.status === "success") {
                this.setState({
                    provincias: responseProvincias.data.provincias,
                })
            }

            //Convenios
            const responseConvenios = await api.clientes_convenios.getAll(this.props.idcliente, queryString.stringify({
                limit: 50,
                page: 1,
            }))
            if (responseConvenios.status === "success") {
                this.setState({
                    convenios: responseConvenios.data.clientes_convenios,
                })

                this.props.form.setFieldsValue({convenios_id: responseConvenios.data.clientes_convenios && responseConvenios.data.clientes_convenios[0] ? responseConvenios.data.clientes_convenios[0].id : null})
            }

        } catch (e) {
            message.error(e.toString(), 7);
        }
    }
    async get_franquiciacobertura (){
        try {
            const responseFranquicia = await api.franquicias.getAtiende(this.state.longitud,this.state.latitud,this.state.cp);
            if (responseFranquicia.status === "success" ) {
                if(responseFranquicia.data.franquicia.id !== 0){
                    this.setState({atiende:responseFranquicia.data.franquicia.codigo+"-"+ responseFranquicia.data.franquicia.franquicia,NOatiende:""})
                    this.get_franquiciaNOcobertura()
                }else{
                    this.setState({atiende:"",NOatiende:""})
                }
            }

        } catch (e) {
            message.error(e.toString(), 7);
        }

    }
    async get_franquiciaNOcobertura (){
        try {
            const responseFranquicia = await api.franquicias.getNoAtiende(this.state.longitud,this.state.latitud,this.state.cp);
            if (responseFranquicia.status === "success" ) {
                if(responseFranquicia.data.franquicia_zona.id !== 0){
                    this.setState({NOatiende:"Domicilio excluido de ENTREGA o RETIRO a domicilio. " + responseFranquicia.data.franquicia_zona.zona})
                }else{
                    this.setState({NOatiende:""})
                }

            }

        } catch (e) {
            message.error(e.toString(), 7);
        }

    }
    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    this.setState({
                        confirmLoading: true,
                    })
                    const response = await api.clientes_domicilios.create({
                        ...values,
                        clientes_id: this.props.idcliente,
                        latitud: values.latitud !== null ? values.latitud.toString() : '',
                        longitud: values.longitud !== null ? values.longitud.toString() : '',
                    });
                    if (response.status === "success") {
                        this.props.onCreate();
                        this.props.closeModal();
                    }else {
                        message.error(response.message, 7);
                    }
                } catch (e) {
                    message.error(e.toString(), 7);
                } finally {
                    this.setState({
                        confirmLoading: false,
                    })
                }
            }
        })
    }

    onSuggestSelect = (suggest) => {
        if (suggest && suggest.gmaps) {
            this.setState({
                localidad: null,
                calle: null,
                altura: null,
                cp: null,
                latitud: null,
                longitud: null,
                provincias_id: null,
                domiciliotipos_id: 2,
            }, () => {
                let localidad="";
                if (suggest && suggest.gmaps && suggest.gmaps.address_components) {
                    for (var i = 0; i < suggest.gmaps.address_components.length; i++) {
                        if (suggest.gmaps.address_components[i].types.indexOf('route') !== -1) {
                            this.props.form.setFieldsValue({calle: suggest.gmaps.address_components[i].long_name})
                        }
                        if (suggest.gmaps.address_components[i].types.indexOf('locality') !== -1) {
                            this.props.form.setFieldsValue({localidad: suggest.gmaps.address_components[i].long_name})
                            localidad=suggest.gmaps.address_components[i].long_name;
                        }
                        if (suggest.gmaps.address_components[i].types.indexOf('sublocality') !== -1) {
                            this.props.form.setFieldsValue({localidad: (localidad !=="" ? localidad:"CABA -") + suggest.gmaps.address_components[i].long_name})
                        }
                        if (suggest.gmaps.address_components[i].types.indexOf('postal_code') !== -1) {
                            this.props.form.setFieldsValue({cp: suggest.gmaps.address_components[i].long_name})
                        }
                        if (suggest.gmaps.address_components[i].types.indexOf('street_number') !== -1) {
                            this.props.form.setFieldsValue({altura: suggest.gmaps.address_components[i].long_name})
                        }
                        if (suggest.gmaps.address_components[i].types.indexOf('administrative_area_level_1') !== -1) {
                            if(suggest.gmaps.address_components[i].short_name==="CABA"){
                                this.props.form.setFieldsValue({provincias_id: _.find(this.state.provincias, ["provincia","Ciudad Autónoma de Buenos Aires"]).id});
                            }else{
                                this.props.form.setFieldsValue({provincias_id: _.find(this.state.provincias, ["provincia",suggest.gmaps.address_components[i].long_name]).id});
                            }
                        }
                    }
                    this.props.form.setFieldsValue({latitud: suggest.location.lat, longitud: suggest.location.lng});
                    // eslint-disable-next-line
                    this.state.latitud = suggest.location.lat
                    // eslint-disable-next-line
                    this.state.longitud = suggest.location.lng
                    // eslint-disable-next-line
                    this.state.cp = this.props.form.cp
                    // eslint-disable-next-line
                    this.state.urlmap=suggest.gmaps.url
                    // eslint-disable-next-line
                    this.state.atiende=""
                    this.get_franquiciacobertura()
                }
            });
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 5 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 19 },
            },
        };
        const formItemLayout1 = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 0 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        return (
            <Modal
              visible={true}
              confirmLoading={this.state.confirmLoading}
              onOk={this.handleSubmit}
              onCancel={this.props.closeModal}
              cancelText="Cancelar"
              okText="Crear"
              width={800}
            >
                <section className="form-v1-container col-md-12">
                    <h4 style={{marginBottom:15}}>Nuevo Domicilio</h4>
                    <Form  style={{marginTop:10}}>
                        <Row gutter={8} >
                            <Col span={20}>
                                {getFieldDecorator('latitud', {
                                                    })(
                                                    <Input type="hidden"/>
                                    )}
                                {getFieldDecorator('longitud', {
                                                })(
                                                <Input type="hidden"/>
                                )}
                                <FormItem  {...formItemLayout} label="Buscar en google">
                                    <Geosuggest
                                        inputClassName="ant-input"
                                        country="AR"
                                        onSuggestSelect={this.onSuggestSelect}
                                        minLength={4}
                                        placeholder="Buscar en google maps"
                                    />
                                </FormItem>
                            </Col>
                            <Col span={4}>
                                <FormItem>
                                    <a href={this.state.urlmap} target="_blank" ><i className="fas fa-map-marker-alt"></i></a>
                                </FormItem>
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={2} >
                            <Col span={15}>
                                <FormItem {...formItemLayout} label="Domicilio" >
                                {getFieldDecorator('calle', {
                                        rules: [{ required: true, message: ' ' }],
                                        })(
                                        <Input size="default"  onChange={this.cambio} placeholder="Calle" />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={3}>
                                <FormItem {...formItemLayout1} >
                                {getFieldDecorator('altura', {
                                    rules: [{ required: true, message: ' ' }],
                                    })(
                                    <Input   id="altura" type="number"  onChange={this.cambio} placeholder="Altura" />
                                )}
                                </FormItem>
                            </Col>
                            <Col span={3}>
                                <FormItem {...formItemLayout1} >
                                {getFieldDecorator('piso',{})(
                                    <Input size="default" placeholder="Piso" />
                                )}
                                </FormItem>
                            </Col>
                            <Col span={3}>
                                <FormItem {...formItemLayout1} >
                                {getFieldDecorator('depto',{})(
                                    <Input size="default" placeholder="Dpto" />
                                )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={2} >
                            <Col span={15}>
                                <FormItem  {...formItemLayout} label="Localidad">
                                {getFieldDecorator('localidad', {
                                    rules: [{ required: true, message: ' '}],
                                })(
                                    <Input size="default"  onChange={this.cambio} placeholder="Localidad"/>
                                )}
                                </FormItem>
                            </Col>
                            <Col span={4}>
                            <FormItem  {...formItemLayout1}>
                                {getFieldDecorator('cp', {
                                    rules: [{ required: true, message: ' '}],
                                    })(
                                    <Input size="default" onChange={this.cambio}  placeholder="Codigo Postal"/>
                                )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={2} >
                            <Col span={15}>
                                <FormItem {...formItemLayout} label="Provincia">
                                {getFieldDecorator('provincias_id',{
                                    rules: [{ required: true, message: ' ' }],
                                })(
                                    <Select placeholder="Provincia"  onChange={this.cambio}  >
                                        {this.state.provincias && this.state.provincias.map((data,index)=>{
                                            return <Option value={data.id} key={index}>{data.provincia}</Option>
                                        })}
                                    </Select>
                                )}
                                </FormItem>
                            </Col>
                        </Row>
                        {this.state.convenios &&
                            <Row gutter={16}>
                                <Col span={24}>
                                    <FormItem label="Convenio:" {...{
                                        labelCol: {sm: { span: 3 },},
                                        wrapperCol: {sm: { span: 16 },},
                                    }}>
                                        {getFieldDecorator('convenios_id', {
                                                rules: [{ required: true, message: ' '}],
                                                })(
                                                <Select style={{width:"100%", marginLeft:5}}>
                                                    {this.state.convenios && this.state.convenios.map((data,index)=>{
                                                        return <Option value={data.id} key={index}>{data.convenio}</Option>
                                                    })}
                                                </Select>
                                            )
                                        }
                                    </FormItem>
                                </Col>
                            </Row>
                        }

                        <Alert
                            message={this.state.latitud  && this.state.latitud !==''? 'Domicilio Geo Posicionado' : 'Domicilio NO Geo Posicionado'}
                            description={this.state.latitud  && this.state.latitud  !==''? '' : 'Busque y seleccione la dirección desde el auto completar del campo  "Buscar de Google:"'}
                            type={this.state.latitud  && this.state.latitud  !==''? 'success' : 'warning'}
                            showIcon
                        />
                    </Form>
                </section>

            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
});

const WrappedNuevo = Form.create()(NuevoDomicilio);

export default connect(
  mapStateToProps,
)(WrappedNuevo);
