
import React from 'react';
import { api } from '../api';
import { AutoComplete, message, Spin, Input } from 'antd';
import queryString from 'query-string';
const AutoCompleteOption = AutoComplete.Option;

export class Localidades extends React.Component {
	_isMounted = false;
	state = {
		autoCompleteLocalidades: [],

	}
	componentWillUnmount() {
		this._isMounted = false;
	}
	componentDidMount() {
		this._isMounted = true;
		// this.fetch();

	}
	componentDidUpdate(prevProps) {
		if (prevProps.localidad !== this.props.localidad) {
			if (this.props.localidad === null) {
				let localidad = this.props.localidad
				console.log("loc null", prevProps.localidad, this.props.localidad)
				this.setState({
					localidad: localidad
				})
			} else {
				if (this.props.localidad.id) {
					if (this._isMounted) {
						console.log("loc not null", prevProps.localidad, this.props.localidad)
						let localidad = this.props.localidad
						this.setState({
							localidad: localidad.localidad
						})
					}
				}

			}
		}
	}
	// fetch = async () => {
	// 	const response = await api.localidades1.getAll()
	// }
	handleLocalidad = async (value) => {
		this.setState({localidad:value})
		if (value.trim().length >= 2) {
			try {
				this.setState({ fetching: true });
				const response = await api.localidades1.getAll(queryString.stringify({
					q: value.trim(),
					// max: 15,
					exacto: "true",
				}))
				this.setState({
					autoCompleteLocalidades: response.data.localidades ? response.data.localidades.map((data, index) => {
						return {
							text: `${data.localidad} - ${data.provincia_nombre}`,
							value: data.id,
						}
					}) : [],
					fetching: false,
				});

			} catch (e) {
				message.error(e.toString(), 7);
			}
		} else if (value.trim().length === 0) {
			this.setState({ autoCompleteLocalidades: [] })
		}
	}
	onSelectLocalidad = async (id) => {
		try {
			//
			const response = await api.localidades1.get(id);
			if (response.status == "success") {
				this.props.SelectLocalidad(response.data.localidad_ar)
				const event = new KeyboardEvent('keydown', { 'key': 'Tab', code: 'Tab', wich: 9 })
				document.dispatchEvent(event)
			} else {
				message.error(response.message, 7);
			}

		} catch (e) {
			message.error(e.toString(), 7);
		}
	}
	render() {
		const localidadesOptions = this.state.autoCompleteLocalidades.map(data => (
			<AutoCompleteOption key={data.value}>{data.text.trim()}</AutoCompleteOption>
		));
		return (
			<AutoComplete
				// allowClear={true}
				style={{ width: '100%' }}
				dataSource={localidadesOptions}
				onChange={this.handleLocalidad}
				placeholder="Ingrese una localidad"
				onSelect={this.onSelectLocalidad}
				notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
				value={this.state.localidad}
			>
				<Input />
			</AutoComplete>
		)
	}
}

export default (Localidades);
