import {config} from 'config'

export const api = {
    nominatim: {
        async search(q) {
            const response = await fetch(`http://3.231.158.201:7070/search.php?q=${q}&addressdetails=1&format=json`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            return data;
        },
    },
    graphhopper: {
        async search(q) {
            const response = await fetch(`https://graphhopper.com/api/1/geocode?q=${q}&locale=ar&key=b8e91983-a896-4a40-80f1-9227fc6b8e32`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            return data;
        },
    },
    session:{
        async uuid() {
          return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
          });
        }
    },
    google: {
        async search(q,lat,lng,token) {

             const response = await fetch(config.URL_SOCKET_API +`/maps?input=${q}&location=${lat},${lng}&sessiontoken=${token}`, {

                method: 'GET',
                // mode: 'no-cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
            });
            const data = await response.json();
            return data;
        },
        async getPlaceId(id, token) {
            // const response = await fetch(`https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api/place/details/json?place_id=${id}&key=${config.GOOGLE_API_KEY}`, {
                const response = await fetch(config.URL_SOCKET_API +`/place?place_id=${id}&sessiontoken=${token}`, {
                method: 'GET',
                // mode: 'no-cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
            });
            const data = await response.json();
            return data;
        }
    }

};
