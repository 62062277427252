
import React from 'react';
import { api } from '../api';
import { AutoComplete, message, Spin, Input } from 'antd';
import queryString from 'query-string';
import { isNull } from 'lodash';

const AutoCompleteOption = AutoComplete.Option;

export class Localidades extends React.Component {
	_isMounted = false;
	state = {
		autoCompleteLocalidades: [],
		q: "",
	}
	componentWillUnmount() {
		this._isMounted = false;
	}
	componentDidMount() {
		this._isMounted = true;
		if (this.props.default) {
			this.setState({ localidad: this.props.default.localidad })
			this.onSelectLocalidad(this.props.default.id)
		}
	}

	handleLocalidad = async (value) => {
		this.setState({ localidad: value })
		this.props.SelectLocalidad(null)
		this.setState({ autoCompleteLocalidades: [] })
		if (!isNull(value) && value != undefined) {
			if (value.trim().length >= 2) {
				try {
					this.setState({ fetching: true, q: value.trim() });
					const carga_util = queryString.stringify({
						q: value.trim(),
						max: 15,
						exacto: "true",
						atendida: this.props.atendida ? this.props.atendida : 1,
					})
					let response;
					if (this.props.prepago) {
						response = await api.localidades1.getAllPrepago(carga_util)
					} else {
						response = await api.localidades1.getAll(carga_util)
					}
					if (response.status === "success") {
						if (this.state.q == value) {
							this.setState({
								autoCompleteLocalidades: response.data.localidades ? response.data.localidades.map((data, index) => {
									return {
										text: `${data.localidad} - ${data.provincia_nombre}`,
										value: data.id,
									}
								}) : [],
								fetching: false,
							});
						}
					} else {
						this.setState({
							fetching: false,
						});
					}
				} catch (e) {
					message.error(e.toString(), 7);
				}
			} else if (value.trim().length === 0) {
				this.setState({ autoCompleteLocalidades: [] })
			}
		}
	}
	onSelectLocalidad = async (id) => {
		try {
			if (id != null) {
				const response = await api.localidades1.get(id);
				if (response.status == "success") {
					this.props.SelectLocalidad(response.data.localidad_ar)
					const event = new KeyboardEvent('keydown', { 'key': 'Tab', code: 'Tab', wich: 9 })
					document.dispatchEvent(event)
					this.setState({ localidad: response.data.localidad_ar.localidad })
				} else {
					message.error(response.message, 7);
				}
			}

		} catch (e) {
			message.error(e.toString(), 7);
		}
	}
	componentDidUpdate(prevProps) {
		if (prevProps.localidad !== this.props.localidad) {
			if (this.props.localidad === null) {
				let localidad = this.props.localidad
				this.setState({
					localidad: localidad
				})
			} else {
				if (this._isMounted) {
					let localidad = this.props.localidad
					this.setState({
						localidad: localidad.localidad
					})
				}
			}
		}

	}
	render() {
		const localidadesOptions = this.state.autoCompleteLocalidades.map(data => (
			<AutoCompleteOption key={data.value}>{data.text.trim()}</AutoCompleteOption>
		));
		return (
			<AutoComplete
				allowClear={true}
				style={{ width: '100%' }}
				dataSource={localidadesOptions}
				onChange={this.handleLocalidad}
				disabled={this.props.disabled || false}
				placeholder="Ingrese una localidad"
				onSelect={(id) => this.onSelectLocalidad(id)}
				autoFocus={this.props.autoFocus || false}
				defaultActiveFirstOption={this.props.defaultActiveFirstOption || true}
				value={this.state.localidad}
				notFoundContent={this.state.fetching ? <Spin size="small" /> : null}
			>
				<Input id="localidad" />
			</AutoComplete>
		)
	}
}

export default (Localidades);
