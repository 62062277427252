export const config = true ? {
    URL_API: 'https://api.credifin.com.ar/api',
    URL_SOCKET: 'https://socket.credifin.com.ar',
    URL_SOCKET_API: 'https://socket.credifin.com.ar',
    GOOGLE_API_KEY: 'AIzaSyAdYxa9N9WsRCQ2NLOj7f5V-TZckugW3bU',
    URL_IMG: 'https://api.credifin.com.ar/static',
    URL_API_LOCALIDAD: 'https://api.credifin.com.ar/api',
    URL_STATIC: 'https://api.credifin.com.ar/static',
    // URL_STATIC: 'https://api.credifin.com.ar/static',
} : {
    URL_API: 'http://localhost:5602/api',
    URL_SOCKET: 'https://socket.credifin.com.ar',
    URL_SOCKET_API: 'https://socket.credifin.com.ar',
    GOOGLE_API_KEY: 'AIzaSyAdYxa9N9WsRCQ2NLOj7f5V-TZckugW3bU',
    URL_IMG: 'http://credifinexpress.com.ar:7600/static',
    URL_API_LOCALIDAD: 'https://api.credifin.com.ar/api',
    URL_APIG: 'https://api.credifin.com.ar/api',
    URL_STATIC: 'https://api.credifin.com.ar/static',
    // URL_STATIC: 'http://localhost:5600/static',
}