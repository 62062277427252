import React from 'react';
import { Select,message} from 'antd';
import {api} from '../api';
import queryString from 'query-string';
import _ from 'lodash';

const Option = Select.Option;

export class Sucursal extends React.Component {

    state = {
        loading:false,
    }
    async componentDidMount(){
      //Franquicias
        this.setState({loading:true,id:this.props.initialValue})
        const responseFranquicias = await api.franquicias.getAllconNodos(queryString.stringify({page:1, limit: 50000}));
        if (responseFranquicias.status === "success") {
            this.setState({
                franquicias: responseFranquicias.data.franquicias,
            })
        }else {
            message.error(responseFranquicias.message, 7);
        }
        this.setState({loading:false})
    }
    componentDidUpdate(prevProps) {
      if (this.props.initialValue !== prevProps.initialValue) {
        this.setState({id:this.props.initialValue});
      }
    }
    render() {
        const { franquicias } = this.state;
        return (
          <Select
          style={{width:"100%"}}
          showSearch
          loading={this.state.loading}
          value={this.state.id}
          disabled={this.props.disabled}
          onSelect={(e)=>{this.props.selectSucursal(_.find(franquicias,["id",e]))}}
          filterOption={(input, option) =>
            option.props.children ? option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : true
          }
          >
            { franquicias && franquicias.map((data, index)=>{
                let f =data.codigo + ' - ' + data.franquicia
                return <Option value={data.id} key={index}>{f}</Option>
              })
            }
          </Select>
        );
    }
}

export default Sucursal;
