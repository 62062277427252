import 'isomorphic-fetch';
import { config } from 'config'

export const api = {
    agildata: {
        async getByDNI(query = '') {
            const response = await fetch(config.URL_API + `/getByDni?${query}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async getByCUIT(cuit) {
            const response = await fetch(config.URL_API + `/getByCuit/${cuit}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
    },
    autogestion_clientes: {
        async create(data) {
            const response = await fetch(config.URL_API + '/autogestion_clientes', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async marcar(id) {
            const response = await fetch(config.URL_API + `/autogestion_clientes/domicilio/${id}`, {
                method: 'PUT',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async searchCliente(q) {
            const response = await fetch(config.URL_API + `/autogestion_clientes/search?${q}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async getAll(query = '') {
            const response = await fetch(config.URL_API + `/autogestion_clientes?${query}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/autogestion_clientes/${id}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async verificar(cuit) {
            const response = await fetch(config.URL_API + `/autogestion_clientes_verificar/${cuit}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async update(data) {
            const response = await fetch(config.URL_API + '/autogestion_clientes', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async validarDatos(query = '') {
            const response = await fetch(config.URL_API + `/clientes_validacion?${query}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async baja(id) {
            const response = await fetch(config.URL_API + '/autogestion_clientes/baja/' + id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
            });
            const responseData = await response.json();
            return responseData;
        },
        async alta(id) {
            const response = await fetch(config.URL_API + '/autogestion_clientes/alta/' + id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
            });
            const responseData = await response.json();
            return responseData;
        },
        async verificarAfip(data) {
            const response = await fetch(config.URL_API + '/clientes_verificar_afip', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
    },
    autogestion_clientes_domicilios: {
        async create(data) {
            const response = await fetch(config.URL_API + '/autogestion_clientes/domicilios', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async AsignarSucursal(franquicias_id, id) {

            const response = await fetch(config.URL_API + `/autogestion_clientes_domicilios/${franquicias_id}/${id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                },
                //   body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async getAll(id, query = '') {
            const response = await fetch(config.URL_API + `/autogestion_clientes/${id}/domicilios?` + query, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async update(data) {
            const response = await fetch(config.URL_API + '/autogestion_clientes/domicilios', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async baja(id) {
            const response = await fetch(config.URL_API + '/autogestion_clientes/domicilios/' + id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
            });
            const responseData = await response.json();
            return responseData;
        },
    },
    autogestion_clientes_contactos: {
        async create(data) {
            const response = await fetch(config.URL_API + '/autogestion_clientes/contactos', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async getAll(id, query = '') {
            const response = await fetch(config.URL_API + `/autogestion_clientes/${id}/contactos?` + query, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async update(data) {
            const response = await fetch(config.URL_API + '/autogestion_clientes/contactos', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async baja(id) {
            const response = await fetch(config.URL_API + '/autogestion_clientes/contactos/' + id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
            });
            const responseData = await response.json();
            return responseData;
        },
    },
    condiciones_iva: {
        async getAll(query = '') {
            const response = await fetch(config.URL_API + `/condicioniva?${query}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/condicioniva/${id}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
    },
    provincias: {
        async getAll() {
            const response = await fetch(config.URL_API + `/provincias`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
    },
    documentos_tipos: {
        async getAll() {
            const response = await fetch(config.URL_API + `/documentotipos`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
    },
    franquicias: {
        async getAllConNodo(query = '') {
            const response = await fetch(config.URL_API + `/franquicias/all?` + query, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async getAtiende(longitud, latitud, cp, convenio) {
            const response = await fetch(config.URL_API + `/franquicias/atiende?longitud=${longitud}&latitud=${latitud}&cp=${cp}&convenio=${convenio}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const responseData = await response.json();
            return responseData;
        },
        async getNoAtiende(longitud, latitud, cp) {
            const response = await fetch(config.URL_API + `/franquicias/noatiende?longitud=${longitud}&latitud=${latitud}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const responseData = await response.json();
            return responseData;
        },
    },
    afip: {
        async consultarCuit(cuit = '') {

            const response = await fetch(config.URL_SOCKET + `/afip/cuit?cuit=${cuit}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
    },
};
