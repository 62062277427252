import React from 'react';
import { Modal, Divider, Button, Table } from 'antd';

export default class Mails extends React.Component {
    state = {
        disabledLogin: false,
        confirmLoading: false,
        columns: [
            {
                title: 'Seleccione un mail',
                dataIndex: 'email',
                key: 'email',
            },
        ],
    }

    handleSubmit = (data) => {
        this.props.onSelectMail(data.email)
    }
    
    render() {
        return (
            <Modal
                visible={true}
                width={500}
                footer={[
                    <Button type="danger" onClick={()=>this.props.closeModal()}>Cerrar</Button>
                ]}
            >
                <section className="form-v1-container col-md-12">
                    {/* <h4 style={{ marginBottom: 15 }}></h4> */}
                    <Divider />
                    <Table
                        pagination={false}
                        style={{ fontSize: '8px' }}
                        columns={this.state.columns}
                        rowKey={(record, index) => index}
                        dataSource={this.props.data}
                        onRow={(record, rowIndex) => {
                            return {
                                onDoubleClick: (e) => {
                                    this.handleSubmit(record)
                                },
                            };
                        }}
                    />
                </section>
            </Modal>
        );
    }
}