import 'isomorphic-fetch';
import {config} from 'config'

export const api = {
    retiros:{
        async getAll(query = '') {
            const response = await fetch(config.URL_API + `/autogestion/retiros?` + query, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
    });
            const data = await response.json();
            return data;
        },
        async get(id){
            const response = await fetch(config.URL_API + `/autogestion/retiros/` + id, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
        });
            const data = await response.json();
            return data;
        },
        async getGuia(id){
            const response = await fetch(config.URL_API + `/autogestion/guia/` + id, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
        });
            const data = await response.json();
            return data;
        },
        async create(data){
            const response = await fetch(config.URL_API + '/autogestion/retiros', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(data)
        });
            const responseData = await response.json();
            return responseData;
        },
        async estados(idretiro){
            const response = await fetch(config.URL_API + `/autogestion/retiros/${idretiro}/enviar`, {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
        },
        // body: JSON.stringify(data)
        });
            const responseData = await response.json();
            return responseData;
        },
        async destino(data){
            const response = await fetch(config.URL_API + '/autogestion/retiros/destinos', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(data)
        });
            const responseData = await response.json();
            return responseData;
        },
        async rechazar(id){
            const response = await fetch(config.URL_API + `/retiros/${id}/rechazar`, {
        method: 'PUT',
        headers: {
        'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
        },
            body: JSON.stringify({})
        });
            const responseData = await response.json();
            return responseData;
        },
        async deleteDestino(id){
            const response = await fetch(config.URL_API + `/autogestion/retiros/${id}`, {
        method: 'DELETE',
        headers: {
        'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
        },
            body: JSON.stringify({})
        });
            const responseData = await response.json();
            return responseData;
        },
        async pdf(id, mimeType){
            const res = await fetch(config.URL_API + `/autogestion/retiros/${id}/imprimir`,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                },
            });
            const buf = await res.arrayBuffer();
            const file = await new File([buf], id+'.pdf', {type:mimeType})
            return file;
        },
    },
    servicios:{
        async create(data) {
            const response = await fetch(config.URL_API + '/servicios', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(data)
    });
            const responseData = await response.json();
            return responseData;
        },
        async getAll(query = '') {
            const response = await fetch(config.URL_API + `/servicios?` + query, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
    });
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/servicios/${id}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
    });
            const data = await response.json();
            return data;
        },
        async update(data){
            const response = await fetch(config.URL_API + '/servicios', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
    });
            const responseData = await response.json();
            return responseData;
        },
        async delete(id){
            const response = await fetch(config.URL_API + '/servicios/'+id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
    });
            const responseData = await response.json();
            return responseData;
        },
    },
    articulos:{
        async create(data) {
            const response = await fetch(config.URL_API + '/articulos', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(data)
    });
            const responseData = await response.json();
            return responseData;
        },
        async getAll(query = '') {
            const response = await fetch(config.URL_API + `/articulos?` + query, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
            });
            const data = await response.json();
            return data;
        },
        async getArticulos(query = '') {
            const response = await fetch(config.URL_API + `/articulos/get?` + query, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
            });
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/articulos/${id}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
    });
            const data = await response.json();
            return data;
        },
        async getByMedidas(query) {
            const response = await fetch(config.URL_API + `/articulos/bultos?` + query, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
    });
            const data = await response.json();
            return data;
        },
        async update(data){
            const response = await fetch(config.URL_API + '/articulos', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
    });
            const responseData = await response.json();
            return responseData;
        },
        async baja(id){
            const response = await fetch(config.URL_API + '/articulos/baja/'+id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
    });
            const responseData = await response.json();
            return responseData;
        },
    },
    rangos_horarios:{
        async getAll(query = '') {
            const response = await fetch(config.URL_API + `/rangoshorarios?${query}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
    });
            const data = await response.json();
            return data;
        },
    },
    clientes_domicilios:{
        async getAll(id, query = '') {
            const response = await fetch(config.URL_API + `/clientes/${id}/domicilios?` + query, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
            });
            const data = await response.json();
            return data;
        },
    },
    clientes:{
        async create(data) {
            const response = await fetch(config.URL_API + '/clientes', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(data)
    });
            const responseData = await response.json();
            return responseData;
        },
        async getAll(query = '') {
            const response = await fetch(config.URL_API + `/clientes?${query}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
    });
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/clientes/${id}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
    });
            const data = await response.json();
            return data;
        },
        async update(data){
            const response = await fetch(config.URL_API + '/clientes', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
    });
            const responseData = await response.json();
            return responseData;
        },
        async baja(id){
            const response = await fetch(config.URL_API + '/clientes/baja/'+id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
    });
            const responseData = await response.json();
            return responseData;
        },
        async alta(id){
            const response = await fetch(config.URL_API + '/clientes/alta/'+id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
    });
            const responseData = await response.json();
            return responseData;
        },
    },
    clientes_contactos:{
    async create(data) {
        const response = await fetch(config.URL_API + '/clientes/contactos', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
            },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        return responseData;
    },
    async getAll(id, query = '') {
        const response = await fetch(config.URL_API + `/clientes/${id}/contactos?` + query, {
            method: 'GET',
            headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
        });
        const data = await response.json();
        return data;
    },
    async get(id) {
        const response = await fetch(config.URL_API + `/clientes/contactos/${id}`, {
            method: 'GET',
            headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
        });
        const data = await response.json();
        return data;
    },
    async update(data){
        const response = await fetch(config.URL_API + '/clientes/contactos', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
            },
            body: JSON.stringify(data)
        });
        const responseData = await response.json();
        return responseData;
    },
    async delete(id){
        const response = await fetch(config.URL_API + '/clientes/contactos/'+id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
            },
        });
        const responseData = await response.json();
        return responseData;
    },
    },
    franquicias:{
        async create(data) {
            const response = await fetch(config.URL_API + '/franquicias', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(data)
    });
            const responseData = await response.json();
            return responseData;
        },
        async getAll(query = '') {
            const response = await fetch(config.URL_API + `/franquicias?` + query, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
    });
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/franquicias/${id}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
    });
            const data = await response.json();
            return data;
        },
        async delete(id){
            const response = await fetch(config.URL_API + '/franquicias/'+id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
    });
            const responseData = await response.json();
            return responseData;
        },
        async getAtiende(longitud,latitud,cp) {
            const response = await fetch(config.URL_API + `/franquicias/atiende?longitud=${longitud}&latitud=${latitud}&cp=${cp}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
    });
            const responseData = await response.json();
            return responseData;
        },
        async getNoAtiende(longitud,latitud,cp) {
            const response = await fetch(config.URL_API + `/franquicias/noatiende?longitud=${longitud}&latitud=${latitud}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
    });
            const responseData = await response.json();
            return responseData;
        },
        async update(data){
            const response = await fetch(config.URL_API + '/franquicias', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
    });
            const responseData = await response.json();
            return responseData;
        },
        async getAllNodos() {
            const response = await fetch(config.URL_API + `/nodos/todos`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
    });
            const data = await response.json();
            return data;
        },
    },
    clientes_convenios:{
        async create(data) {
            const response = await fetch(config.URL_API + '/clientes/convenios', {
			  method: 'POST',
			  headers: {
			    'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
			  },
			  body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async getAll(id, query = '') {
            const response = await fetch(config.URL_API + `/clientes/${id}/convenios`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/clientes/convenios/${id}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async update(data){
            const response = await fetch(config.URL_API + '/clientes/convenios', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async delete(id){
            const response = await fetch(config.URL_API + '/clientes/convenios/'+id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
			});
            const responseData = await response.json();
            return responseData;
        },
    },
    provincias:{
        async getAll() {
            const response = await fetch(config.URL_API + `/provincias`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
    },
    clientes_destino:{
        async create(data) {
            const response = await fetch(config.URL_API + '/clientes/destinos', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async getAll(id, query = '') {
            const response = await fetch(config.URL_API + `/clientes/${id}/destinos?` + query, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
            });
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/clientes/destinos/${id}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
            });
            const data = await response.json();
            return data;
        },
        async update(data){
            const response = await fetch(config.URL_API + '/clientes/destinos', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async delete(id){
            const response = await fetch(config.URL_API + '/clientes/destinos/'+id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
            });
            const responseData = await response.json();
            return responseData;
        },
    },
    condiciones_iva:{
        async getAll(query = '') {
            const response = await fetch(config.URL_API + `/condicioniva?${query}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
    },
};
