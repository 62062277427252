import React from 'react';
import { api } from '../api';
import { connect } from 'react-redux';
import { message, Button, Form, Input, Select, Row, Col, Alert, Switch, Tooltip } from 'antd';
import _, { isNull } from 'lodash';
import Localidades from './Localidades_new'
import queryString from 'query-string';
import Search from './Search';
import PropTypes from 'prop-types';
import Map from './Map'
import './styles.scss'
import PhoneInput from 'react-phone-number-input/input'
const Option = Select.Option;
const FormItem = Form.Item;
const utilizeFocus = () => {
    const ref = React.createRef()
    const setFocus = () => { ref.current && ref.current.Myref.focus() }

    return { setFocus, ref }
}
class NuevoDomicilio extends React.Component {

    constructor(props) {
        super(props)
        this.input1Focus = utilizeFocus()
    }
    state = {
        zonas: [],
        franquiciaExcluir: null,
        franquicias_id: null,
        disabledLogin: false,
        confirmLoading: false,
        disabled: true,
        OpenMaps: false,
        atiende: "",
        NOatiende: '',
        autoCompleteCalles: [],
        domiciliotipos_id: 2,
        provincias: [],
        lat: null,
        provincia: '',
        lng: null,
        latitud: null,
        longitud: null,
        clientes_convenios_id: null,
        flag: true,
        errorDireccion: "",
        warningDireccion: "",
        retirasucursal: false,
        localidad: this.props.domicilio.localidad ? {
            localidades_ar_id: this.props.domicilio.localidades_ar_id,
            localidad: this.props.domicilio.localidad.localidad
        } : { localidades_ar_id: 0, localidad: null },
    }
    async componentDidMount() {

        //try {
        this.setState({
            contactoDom: this.props.domicilio.contacto,
            telefonoDom: this.props.domicilio.telefono,
            mailDom: this.props.domicilio.mail,
            calle: this.props.domicilio.calle,
            altura: this.props.domicilio.altura,
            piso: this.props.domicilio.piso,
            depto: this.props.domicilio.depto,
            cp: this.props.domicilio.cp,
            localidad: this.props.domicilio.localidad ? {
                localidades_ar_id: this.props.domicilio.localidades_ar_id,
                localidad: this.props.domicilio.localidad.localidad
            } : null,
            domiciliotipos_id: this.props.domicilio.domiciliotipos_id,
            //     franquicias_id: this.props.domicilio.franquicias_id,
            latitud: `${this.props.domicilio.latitud}`,
            longitud: `${this.props.domicilio.longitud}`,
            provincias_id: this.props.domicilio.provincias_id,
            localidades_ar_id: this.props.domicilio.localidades_ar_id,
            retirasucursal: this.props.domicilio.retirasucursal,
            obs: this.props.domicilio.obs,
            retiraen: this.props.domicilio.retirasucursal ? this.props.domicilio.franquicias_id : '',

        })

        //Provincias
        const responseProvincias = await api.provincias.getAll();
        if (responseProvincias.status == "success") {
            this.setState({
                provincias: responseProvincias.data.provincias,
            })
        }

        //Domicilios tipos
        const responseDT = await api.domicilios_tipos.getAll();
        if (responseDT.status == "success") {
            this.setState({
                domicilios_tipos: responseDT.data.domicilios_tipos,
            })
        }
        //this.input1Focus.value=this.state.calle
        if (!isNull(this.props.domicilio.latitud)) {
            if (this.props.domicilio.franquicias_id !== 0) {
                this.setState({ franquicias_id: this.props.domicilio.franquicias_id })
            } else {
                const responseExcluir = await api.coberturaGeo.getExcluido(queryString.stringify({
                    latitud: this.props.domicilio.latitud,
                    longitud: this.props.domicilio.longitud,
                    franquicias_id: this.state.franquicias_id,
                }))
                if (responseExcluir.status == "success") {
                    this.setState({
                        franquiciaExcluir: responseExcluir.data.franquicia_zona,
                    })
                }
            }
        }


        //} catch (e) {
        //  message.error(e.toString(), 7);
        // }
    }

    componentDidUpdate(prevProps) {
        if (this.props.validar !== prevProps.validar && this.props.validar) {
            this.props.form.validateFields({ force: true }, async (err, values) => {
                if (!err) {
                    this.setState({ contactoDom: values.contactoDom, mailDom: values.mailDom })
                    let error = true
                    if (!this.state.latitud || this.state.latitud.length == 0) {
                        message.error("Debe geoposisionar la dirección.");
                        error = false
                    }
                    if (this.state.retirasucursal && !this.state.franquicias_id > 0) {
                        message.error("Por favor seleccione la sucursal de retiro.")
                        error = false
                    }
                    if (values.telefonoDom && values.telefonoDom.length != 13) {
                        this.props.form.setFields({
                            telefonoDom: {
                                value: values.telefonoDom,
                                errors: [new Error('Número de teléfono inválido')],
                            },
                        });
                        error = false
                    }

                    // Validar telefonos y mails                        
                    if (this.props.documentotipos_id !== 3) {
                        const response = await api.clientes.validarDatos(queryString.stringify({
                            telefono: values.telefonoDom.substr(3, 10),
                            mail: values.mailDom,
                        }))
                        if (response.status === "success" && response.data.totalDataSize > 0) {
                            for (var i = 0; i < response.data.validacion_clientes.length; i++) {
                                if ('+54' + response.data.validacion_clientes[i].telefono == values.telefonoDom) {
                                    this.props.form.setFields({
                                        telefonoDom: {
                                            value: values.telefonoDom,
                                            errors: [new Error('Número de teléfono inválido')],
                                        },
                                    });
                                }
                                if (response.data.validacion_clientes[i].mail.toString().toUpperCase() == values.mailDom.toString().toUpperCase()) {
                                    this.props.form.setFields({
                                        mailDom: {
                                            value: values.mailDom,
                                            errors: [new Error('Cuenta de correo inválida')],
                                        },
                                    });
                                }
                            }
                            error = false
                        }
                        if (this.state.domOK) {
                            message.error("!! Atención el domicilio seleccionado no corresponde a la localidad.")
                            error = false
                        }
                    }

                    this.setDomicilio()
                    this.props.validacion(error);
                } else {
                    this.props.validacion(false);
                }
            })
        }
    }

    setDomicilio = async () => {

        this.props.onReturn({
            calle: this.props.form.getFieldValue("calle"),
            altura: this.props.form.getFieldValue("altura"),
            piso: this.props.form.getFieldValue("piso"),
            depto: this.props.form.getFieldValue("depto"),
            cp: this.props.form.getFieldValue("cp"),
            localidad: this.state.localidad,
            domiciliotipos_id: this.state.domiciliotipos_id,
            latitud: this.state.latitud,
            longitud: this.state.longitud,
            provincias_id: this.state.provincias_id ? this.state.provincias_id : 0,
            mail: this.props.form.getFieldValue("mailDom"),
            obs: this.props.form.getFieldValue("obs"),
            telefono: this.props.form.getFieldValue("telefonoDom"),
            contacto: this.props.form.getFieldValue("contactoDom"),
            localidades_ar_id: this.state.localidades_ar_id,
            retirasucursal: this.state.retirasucursal,
            franquicias_id: this.state.retirasucursal ? this.state.retiraen * 1 : null,
        })

    }
    pegarcontacto = () => {
        this.props.form.setFieldsValue({
            contactoDom: this.props.contactocli.contacto,
            telefonoDom: this.props.contactocli.telefono,
            mailDom: this.props.contactocli.mail,
        })

    }
    handleCpChange = (e) => {
        e.preventDefault();
    }
    SelectLocalidad = async (data) => {
        this.setState({
            posisionado: false,
        })
        if (data && data.provincia_id) {
            if (this.state.provincias.length == 0) {
                //Provincias
                const responseProvincias = await api.provincias.getAll();
                if (responseProvincias.status == "success") {
                    this.setState({
                        provincias: responseProvincias.data.provincias,
                    })
                }
            }
            const j = this.state.provincias.findIndex(item => data.provincia_id == item.codigo);

            this.props.form.setFieldsValue({
                provincias_id: this.state.provincias[j].id,
                localidad: data.localidad,
                cp: data.cp,
            })
            if (this.state.localidad && this.state.localidad.id && data.id != this.state.localidad.id) {
                this.props.form.setFieldsValue({
                    calle: "",
                    altura: "",
                    longitud: "",
                    latitud: ""
                })
                this.setState({
                    calle: "",
                    altura: "",
                    longitud: "",
                    latitud: ""
                });
            }
            const response = await api.cobertura.getCoberturaporLocalidad(data.id)

            this.setState({
                localidad: data,
                localidades_ar_id: data.id,
                disabled: false,
                provincia: this.state.provincias[j].provincia,
                provincias_id:
                    this.state.provincias[j].id,
                cp: data.cp
            });
            let path = []
            let zonas = response.data.zonasexcluidas ? response.data.zonasexcluidas : []
            for (var i = 0; i < zonas.length; i++) {
                if (zonas[i].geotxt && zonas[i].geotxt !== "") {
                    const coordinates = zonas[i].geotxt.split(',').map((data, index) => {
                        return data.split(' ');
                    });
                    let area = []
                    for (var x = 0; x < coordinates.length; x++) {
                        area.push({ lat: coordinates[x][1] * 1, lng: coordinates[x][0] * 1 })
                    }
                    path.push({ area: area })
                }
            }
            if (response.status == "success") {
                this.setState({
                    franquicia: response.data.franquicias_localidad.franquicia,
                    franquicias_id: response.data.franquicias_localidad.franquicia ? response.data.franquicias_localidad.franquicia.id : 0,
                    zonas: path,
                    redespacho: response.data.franquicias_localidad.redespacho,
                    depositos: response.data.depositos,
                    OpenMaps: this.props.domicilio.latitud == null ? false : true,
                })

            }

            this.setDomicilio()
            this.input1Focus.setFocus()
        } else {
            this.setState({
                franquicia: null,
                franquicias_id: null,
            })
        }
        this.setDomicilio()
    }

    ChangeMarker = async (data) => {
        this.setState({
            posisionado: true,
            domOK: false,
        })
        const response = await api.google.getGeocode(data.latitud, data.longitud);
        if (response.status == "OK") {
            let address_components = response.results[0].address_components;
            let localidad = ""
            for (var i = 0; i < address_components.length; i++) {
                if (address_components[i].types.indexOf('locality') !== -1) {
                    localidad = address_components[i].long_name;
                }
                if (address_components[i].types.indexOf('administrative_area_level_1') !== -1) {
                    localidad = (address_components[i].short_name == "CABA" ? "Capital Federal" : localidad);
                }
            }

            var place_localidad = String(localidad).toUpperCase()
            var state_localidad = this.state.localidad.localidad ? String(this.state.localidad.localidad).toUpperCase() : ''
            if (place_localidad !== state_localidad) {
                const response = await api.coberturaGeo.get(queryString.stringify({
                    latitud: data.latitud,
                    longitud: data.longitud,
                    clientes_domicilios_id: 0,
                }))
                if (response.status == "success") {
                    if (this.state.franquicias_id != response.data.franquicia.id) {
                        message.error("!! Atención el domicilio seleccionado en localidad (" + localidad + ") no corresponde a la localidad seleccionada (" + this.state.localidad.localidad + ").")
                        this.setState({ domOK: true })
                    }
                }
            }
        }

        const responseExcluir = await api.coberturaGeo.getExcluido(queryString.stringify({
            latitud: data.latitud,
            longitud: data.longitud,
            franquicias_id: this.state.franquicias_id,
        }))
        if (responseExcluir.status == "success") {
            this.setState({
                franquiciaExcluir: responseExcluir.data.franquicia_zona,
            })
        }
        this.props.form.setFieldsValue({
            calle: "",
            altura: "",
        })
        this.setState({
            calle: "",
            altura: "",
        });
        this.setDomicilio()
    }
    handleInputChange = (e, name) => {
        this.setState({
            [name]: e.target.value,
        })
        this.setDomicilio()
    }
    handleInputChangeAltura = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ altura: e.target.value })
        }
        this.setDomicilio()
    }
    handleInputChangeCalle = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ calle: e.target.value })
        }
        this.setDomicilio()
    }

    selectResult = async (place) => {
        try {
            this.props.form.setFields({
                retiraen: undefined
            });

            this.setState({
                OpenMaps: false,
                posisionado: false,
                domOK: false,
                retirasucursal: false,
                retiraen: undefined
            })

            var place_localidad = String(place.localidad).toUpperCase()
            var state_localidad = this.state.localidad.localidad ? String(this.state.localidad.localidad).toUpperCase() : ''
            if (place_localidad !== state_localidad) {
                const response = await api.coberturaGeo.get(queryString.stringify({
                    latitud: place.latitud,
                    longitud: place.longitud,
                    clientes_domicilios_id: 0,
                }))
                if (response.status == "success") {
                    if (!this.state.redespacho) {
                        if (this.state.franquicias_id != response.data.franquicia.id) {
                            message.error("!Atención, el domicilio seleccionado no corresponde a la localidad!")
                            this.setState({ domOK: true })
                        }
                    }
                    this.setState({
                        franquicia: response.data.franquicia,
                        franquicias_id: response.data.franquicia ? response.data.franquicia.id : 0,
                    })
                }

            }
            const responseExcluir = await api.coberturaGeo.getExcluido(queryString.stringify({
                latitud: place.latitud,
                longitud: place.longitud,
                franquicias_id: this.state.franquicias_id,
            }))

            if (responseExcluir.status == "success") {
                this.setState({
                    franquiciaExcluir: responseExcluir.data.franquicia_zona,
                    zona: responseExcluir.data.franquicia_zona ? responseExcluir.data.franquicia_zona.id > 0 ? responseExcluir.data.franquicia_zona.geotxt : '' : '',
                })
            }
            this.props.form.setFieldsValue({
                calle: place.calle,
                altura: place.altura,
                piso: place.piso,
                depto: place.depto,
                //cp: place.cp,
            })
            this.setState({
                calle: place.calle,
                altura: place.altura,
                piso: place.piso,
                depto: place.depto,
                //cp: place.cp,
                latitud: place.latitud,
                longitud: place.longitud,
                OpenMaps: true,
            })
        } catch (error) {
            message.error(error)
        }
        this.setDomicilio()
    }

    handleChangeRetira = (e) => {
        const found = this.state.depositos.find(item => item.id === e)
        if (found) {
            this.props.form.setFieldsValue({
                calle: found.calle,
                altura: found.altura
            });
            this.setState({ calle: found.calle })
        }

        this.setState({ retiraen: e });
    }

    controlMail() {
        let controlMail = false, mail = this.props.form.getFieldValue("mailDom") || ""
        controlMail = mail.toUpperCase().includes('@credifin'.toUpperCase()) || mail.toUpperCase().includes('@mail'.toUpperCase())
        if (controlMail) {
            this.props.form.setFields({
                mailDom: {
                    value: mail,
                    errors: [new Error('El dominio de esta direccion email no está permitido en este sistema')],
                },
            });
            return
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form autoComplete="off" layout='vertical' >
                <Row gutter={1}>
                    <Col span={12}>
                        <Form.Item label="Localidad" >
                            {getFieldDecorator('localidad', {
                                rules: [{ required: true, message: 'Debe ingresar una localidad' }],
                            })(
                                <Localidades
                                    SelectLocalidad={(data) => {
                                        this.SelectLocalidad(data)
                                    }}
                                    atendida={0}
                                    autoFocus={true}
                                    default={this.props.domicilio.localidad ? { id: this.props.domicilio.localidades_ar_id, localidad: this.props.domicilio.localidad.localidad } : null}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="CP" >
                            {getFieldDecorator('cp', {
                                initialValue: this.state.cp,
                            })(
                                <Input
                                    readOnly
                                    onChange={(e) => this.setState({ cp: e.target.value })}
                                    placeholder="Cp" maxLength={10} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Provincia" >
                            {getFieldDecorator('provincia', {
                                initialValue: this.state.provincia,
                            })(
                                <Input
                                    readOnly
                                    placeholder="Provincia" />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={1}>
                    <Col span={14}>
                        {this.state.posisionado &&
                            <Form.Item label="Escribir Calle" >
                                {getFieldDecorator('calle', {
                                    initialValue: this.state.calle ? this.state.calle : '',
                                    rules: [{ required: true, message: 'Debe ingresar una dirección' }],
                                })(
                                    <Input
                                        disabled={!this.state.localidad || !this.state.localidad.localidad}
                                        onChange={(e) => this.handleInputChangeCalle(e)}
                                        placeholder="Calle" maxLength={100} />
                                )}
                            </Form.Item>

                        }
                        {!this.state.posisionado &&
                            <Form.Item label="Escribir calle, altura o lugar (Google Maps)" >
                                {getFieldDecorator('calle', {
                                    initialValue: this.state.calle ? this.state.calle : '',
                                    rules: [{ required: true, message: 'Debe ingresar una dirección' }],
                                })(
                                    <Search
                                        disabled={(!this.state.localidad || !this.state.localidad.localidad)}
                                        provider='google'
                                        defaultActiveFirstOption={true}
                                        onSelectResult={(place) => {
                                            this.selectResult(place)
                                        }}
                                        calle={this.state.calle ? this.state.calle : ''}
                                        // ref={this.input1Focus.ref}
                                        localidad={this.state.localidad ? this.state.localidad : ''}
                                    />
                                )}
                            </Form.Item>
                        }
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Altura" >
                            {getFieldDecorator('altura', {
                                initialValue: this.state.altura ? this.state.altura : '',
                                rules: [{ required: true, message: 'Debe ingresar la ALTURA de la dirección' }],
                            })(
                                <Input
                                    readOnly={!this.state.ChangeMarker}
                                    disabled={(!this.state.localidad || !this.state.localidad.localidad)}
                                    onChange={(e) => this.handleInputChangeAltura(e)}
                                    placeholder="Altura" maxLength={6} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item label="Piso" >
                            {getFieldDecorator('piso', {
                                initialValue: this.state.piso ? this.state.piso : '',
                            })(
                                <Input
                                    disabled={!this.state.localidad || !this.state.localidad.localidad}
                                    onChange={(e) => this.handleInputChange(e, 'piso')}
                                    placeholder="Piso" maxLength={6} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item label="Depto" >
                            {getFieldDecorator('depto', {
                                initialValue: this.state.depto ? this.state.depto : '',
                            })(
                                <Input
                                    disabled={!this.state.localidad || !this.state.localidad.localidad}
                                    onChange={(e) => this.handleInputChange(e, 'depto')}
                                    placeholder="Depto" maxLength={6} />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                {!this.state.redespacho ?
                    <>
                        {this.state.franquicia && this.state.franquicias_id > 0 &&
                            <Alert
                                message={"Sucursal de Cobertura: " + this.state.franquicia.codigo + " - " + this.state.franquicia.franquicia}
                                type="success"
                            >
                            </Alert>
                        }
                        {this.state.localidad && (!this.state.franquicia || !this.state.franquicias_id > 0) &&
                            <Alert
                                message={"Localidad sin cubertura"}
                                type="error"
                            >
                            </Alert>
                        }
                    </>
                    :
                    <>
                        {this.state.franquicia && this.state.franquicias_id > 0 &&
                            <Alert
                                message={"Sucursal de Cobertura: " + this.state.franquicia.codigo + " - " + this.state.franquicia.franquicia + ". Cobertura por redespacho."}
                                type="success"
                            >
                            </Alert>
                        }
                    </>
                }


                {this.state.OpenMaps &&
                    <div style={{ marginTop: 10 }}>
                        <span style={{ fontSize: 14 }} >@{this.state.latitud}, {this.state.longitud}</span>
                        <span style={{ fontSize: 14, color: "Red", marginBottom: 10 }} > Verifique el marcador de geo posición y reubíquelo en el mapa si es necesario</span>
                        <Map
                            flag={this.state.flag}
                            lat={this.state.latitud * 1}
                            lng={this.state.longitud * 1}
                            zonas={this.state.zonas}
                            onChangePos={(la, lo) => {
                                this.props.form.setFieldsValue({
                                    latitud: la,
                                    longitud: lo,
                                });
                                this.setState({ latitud: la, longitud: lo, flag: false, ChangeMarker: true })
                                this.ChangeMarker({ latitud: la, longitud: lo })
                            }}
                            leido={(leido) => { this.setState({ buscando: leido, flag: false }) }}
                        />
                        {this.state.franquiciaExcluir && this.state.franquiciaExcluir.id > 0 &&
                            <Alert style={{ marginTop: 10 }}
                                message={"Las coordenadas están en zona excluida,    de entrega en domicilio. "}
                                type="error"
                            >
                            </Alert>
                        }
                    </div>
                }
                <Row gutter={12}>
                    {this.state.atiende !== "" &&
                        <div className="ant-form-item-control has-error">
                            <div className="ant-form-explain">{this.state.atiende}</div>
                        </div>
                    }
                </Row>
                <Row gutter={8} style={{ marginTop: 10 }}>
                    <Col span={9}>
                        <FormItem label="Tipo Domicilio" {...{
                            labelCol: {
                                xs: { span: 24 },
                                sm: { span: 11 },
                            },
                            wrapperCol: {
                                xs: { span: 24 },
                                sm: { span: 13 },
                            },
                        }} >
                            {getFieldDecorator('domiciliotipos_id', {
                                initialValue: this.state.domiciliotipos_id ? this.state.domiciliotipos_id : '',
                            })(
                                <Select
                                    placeholder="Tipo Domicilio"
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    showSearch
                                    onSelect={(e) => this.setState({ domiciliotipos_id: e })}
                                    filterOption={(input, option) =>
                                        option.props.children ? option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : true
                                    }
                                >
                                    {this.state.domicilios_tipos && this.state.domicilios_tipos.map((data, index) => {
                                        return <Option value={data.id} key={index}>{data.domicilio_tipo}</Option>
                                    })}
                                </Select>
                            )}
                        </FormItem>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Retira de sucursal" {...{
                            labelCol: { sm: { span: 18 }, },
                            wrapperCol: { sm: { span: 6 }, },
                        }}>
                            <Switch onChange={(e) => { this.setState({ retirasucursal: e }) }} checked={this.state.retirasucursal} />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        {this.state.retirasucursal &&
                            <Form.Item label="Suc." {...{
                                labelCol: { sm: { span: 4 }, },
                                wrapperCol: { sm: { span: 20 }, },
                            }}>
                                {getFieldDecorator('retiraen', {
                                    initialValue: this.state.retiraen ? this.state.retiraen : '',
                                    rules: [{ required: this.state.retirasucursal, message: 'Seleccione una sucursal de retiro' }],
                                })(
                                    <Select
                                        placeholder="Sucursal"
                                        style={{ width: "100%" }}
                                        optionFilterProp="children"
                                        showSearch
                                        value={this.state.retiraen}
                                        onSelect={(e) => this.handleChangeRetira(e)}
                                        filterOption={(input, option) =>
                                            option.props.children ? option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : true
                                        }
                                    >
                                        {this.state.depositos && this.state.depositos.map((data, index) => {
                                            return <Option value={data.id} key={index}>{data.franquicia}</Option>
                                        })}
                                    </Select>
                                )}
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row gutter={8} >
                    <Col span={20}>
                        <FormItem {...{
                            labelCol: {
                                xs: { span: 24 },
                                sm: { span: 5 },
                            },
                            wrapperCol: {
                                xs: { span: 24 },
                                sm: { span: 19 },
                            },
                        }} label="Contacto">
                            {getFieldDecorator('contactoDom', {
                                initialValue: this.state.contactoDom ? this.state.contactoDom : '',
                                rules: [{ required: true, message: 'El Contacto es un dato requerido' }],
                            })(
                                <Input size="default" placeholder="Contacto" />
                            )}
                        </FormItem>
                    </Col>
                    {!this.props.soloDom &&
                        <Col span={4}>
                            <Tooltip title="Copiar los datos de contacto del cliente">
                                <Button onClick={this.pegarcontacto}>Autocompletar</Button>
                            </Tooltip>
                        </Col>
                    }
                </Row>
                {this.props.documentotipos_id !== 3 &&
                    <>
                        <Row gutter={8} >
                            <Col span={20}>
                                <FormItem label="Celular" {...{
                                    labelCol: {
                                        xs: { span: 24 },
                                        sm: { span: 5 },
                                    },
                                    wrapperCol: {
                                        xs: { span: 24 },
                                        sm: { span: 4 },
                                    },
                                }} extra="Ingresar codigo de area sin el cero y numero sin el 15 (10 digitos.)">
                                    {getFieldDecorator('telefonoDom', {
                                        initialValue: this.state.telefonoDom ? this.state.telefonoDom : '',
                                        rules: [{ required: true, message: 'El Telefono es un dato requerido' }],
                                    })(
                                        <PhoneInput
                                            className={"ant-input"}
                                            placeholder="Telefono"
                                            country="AR"
                                            maxLength={12}
                                            onChange={phone => this.setState({ telefonoDom: phone })}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={8} >
                            <Col span={20}>
                                <FormItem label="Email" {...{
                                    labelCol: {
                                        xs: { span: 24 },
                                        sm: { span: 5 },
                                    },
                                    wrapperCol: {
                                        xs: { span: 24 },
                                        sm: { span: 19 },
                                    },
                                }} >
                                    {getFieldDecorator('mailDom', {
                                        initialValue: this.state.mailDom ? this.state.mailDom : '',
                                        rules: [{ required: false, type: 'email', message: 'La cuenta de mail tiene errores' }],
                                    })(
                                        <Input size="default" placeholder="Email" onBlur={() => this.controlMail()} />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </>
                }

                <Row gutter={8} >
                    <Col span={24}>
                        <FormItem label="Observaciones" {...{
                            labelCol: {
                                xs: { span: 24 },
                                sm: { span: 4 },
                            },
                            wrapperCol: {
                                xs: { span: 24 },
                                sm: { span: 19 },
                            },
                        }} >
                            {getFieldDecorator('obs', {
                                initialValue: this.state.obs ? this.state.obs : '',
                            })(
                                <Input.TextArea size="default" placeholder="Ejemplo: Planta baja A" />
                            )}
                        </FormItem>
                    </Col>
                </Row>
            </Form>
        );
    }
}
const mapStateToProps = (state) => ({
    user: state.user,
});

const WrappedNuevo = Form.create()(NuevoDomicilio);

export default connect(
    mapStateToProps,
)(WrappedNuevo);

NuevoDomicilio.propTypes = {
    provider: PropTypes.string.isRequired,
    onReturn: PropTypes.func.isRequired,
}
