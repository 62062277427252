import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Input, AutoComplete, Spin } from 'antd';
import { api } from './api';
import { utils } from '../../utils';
const Option = AutoComplete.Option;
const { Search } = Input;
export default class Complete extends React.Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
      }
    state = {
        dataSource: [],
        search: '',
        
    }
    async componentDidMount() {
        let session = await api.session.uuid();
        this.setState({ loading: true, id: this.props.initialValue, token: session })
    }
    handleSearch = async (value) => {
        let result;
        this.setState({ fetching: true, })
        if (!value || value.indexOf('@') >= 0) {
            result = [];
        } else {
            if (this.props.provider == 'google') {
                const response = await api.google.search(value, this.props.localidad.centroide_lat, this.props.localidad.centroide_lon, this.state.token);
                this.setState({
                    dataSource: response.predictions.map((data, index) => {
                        return {
                            text: data.description,
                            value: JSON.stringify(data),
                        }
                    }),
                    fetching: false,
                });
            }
        }

    }
    handleSelect = async (selectedPrediction) => {
        try {
            selectedPrediction = JSON.parse(selectedPrediction)

            let place = {
                calle: '',
                altura: '',
                localidad: '',
                cp: '',
                provincia: '',
                latitud: '',
                longitud: '',
            }

            //Google
            //========================================================>
            if (this.props.provider == "google") {
                const response = await api.google.getPlaceId(selectedPrediction.place_id, this.state.token);
                if (response.status != "OK") {
                    return;
                }
                // let session = await api.session.uuid();
                // this.setState({ token: session })
                let address_components = response.result.address_components;

                for (var i = 0; i < address_components.length; i++) {
                    if (address_components[i].types.indexOf('route') !== -1) {
                        place.calle = address_components[i].long_name;
                    }
                    if (address_components[i].types.indexOf('locality') !== -1) {
                        place.localidad = address_components[i].long_name;
                    }
                    if (address_components[i].types.indexOf('sublocality') !== -1) {
                        place.localidad = (place.localidad !== "" ? place.localidad : "CABA -") + address_components[i].long_name;
                    }
                    if (address_components[i].types.indexOf('administrative_area_level_1') !== -1 && address_components[i].types.indexOf('political') !== -1) {
                        place.provincia = address_components[i].long_name;
                    }
                    if (address_components[i].types.indexOf('postal_code') !== -1) {
                        place.cp = utils.Numeros(address_components[i].long_name);
                    }
                    if (address_components[i].types.indexOf('street_number') !== -1) {
                        place.altura = address_components[i].long_name;
                    }
                }
                place.latitud = response.result.geometry.location.lat;
                place.longitud = response.result.geometry.location.lng;
            }
            this.props.onSelectResult(place);

        } catch (e) {
            console.log(e.toString());
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.initialValue !== prevProps.initialValue) {
            this.setState({ id: this.props.initialValue });
        }
    }
    render() {
        const { dataSource, fetching } = this.state;
        return (
            <AutoComplete
                // {...this.props}
                style={{ width: '100%' }}
                dataSource={dataSource ? dataSource.map((item) => {
                    return <Option key={item.value}>{item.text}</Option>
                }) : []}
                ref={this.myRef}
                onSelect={this.handleSelect}
                onSearch={this.handleSearch}
                defaultActiveFirstOption={false}
                notFoundContent={fetching ? <Spin size="small" /> : null}
            >
                <Input suffix={this.props.value ? <Icon type="search" onClick={()=>{this.handleSearch(this.props.value ? this.props.value : '')}} /> : ''} prefix={<Icon type="environment" />} placeholder="Ingrese una dirección..." />
            </AutoComplete>
        );
    }
}

Complete.propTypes = {
    onSelectResult: PropTypes.func.isRequired,
}
