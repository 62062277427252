import 'isomorphic-fetch';
import { config } from './config'

export const api = {
    auth: {
        async login(data) {
            const response = await fetch(config.URL_API + '/clientes/autogestion/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async getAuthenticatedUser() {
            const response = await fetch(config.URL_API + '/clientes/autogestion/authenticated', {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },

    },
    user: {
        async create(data) {
            const response = await fetch(config.URL_API + '/clientes/autogestion', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async getAll(query = '') {
            const response = await fetch(config.URL_API + `/clientes/autogestion?${query}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/clientes/autogestion${id}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async update(data) {
            const response = await fetch(config.URL_API + '/clientes/autogestion', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async changePassword(id, data) {
            const response = await fetch(config.URL_API + '/password/' + id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async blanquearClave(id, data) {
            const response = await fetch(config.URL_API + '/blanqueo/' + id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        }
    },
    provincias: {
        async getAll() {
            const response = await fetch(config.URL_API + `/provincias`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
    },
    localidades: {
        async getAll(params = '') {
            const response = await fetch(config.URL_API + '/clientes/localidades?' + params, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + '/localidad/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                }
            });
            const data = await response.json();
            return data;
        },
    },
    franquicias: {
        async getAllconNodos(query = '') {
            const response = await fetch(config.URL_API + `/franquicias/all?` + query, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
    },
    provincias: {
        async getAll() {
            const response = await fetch(config.URL_API + `/provincias`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
    },
    domicilios_tipos: {
        async getAll(params = '') {
            const response = await fetch(config.URL_API + '/domicilios_tipos?' + params, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                },
            });
            const data = await response.json();
            return data;
        },
    },
    direcciones: {
        async getCalles(params = '') {
            const response = await fetch(config.URL_API + '/calles?' + params + '&aplanar', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            return data;
        },
        async getDirecciones(params = '') {
            const response = await fetch(config.URL_API + '/direcciones?' + params + '&aplanar', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            return data;
        },
    },
    cobertura: {
        async getCobertura(data) {
            const response = await fetch(config.URL_API + '/cobertura', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async getCoberturaporLocalidad(id) {
            const response = await fetch(config.URL_API + '/cobertura/localidad/'+id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                }
            });
            const responseData = await response.json();
            return responseData;
        },
    },
    localidades1: {
        async getAll(params = '') {
            const response = await fetch(config.URL_API + '/localidades?' + params, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            return data;
        },
        async getAllPrepago(params = '') {
            const response = await fetch(config.URL_API + '/localidades/prepago?' + params, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/localidad/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            return data;
        },
    },
    agildata: {
        async login(data) {
            const response = await fetch(config.URL_AGILDATA + '/account/authenticate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async getByDNI(query = '') {
            const response = await fetch(config.URL_API + `/getByDni?${query}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async getByCUIT(cuit) {
            const response = await fetch(config.URL_API + `/getByCuit/${cuit}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async getExcluido(query = '') {
            const response = await fetch(config.URL_APIG + `/coberturageoexluir?${query}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
    },
    provincias: {
        async getAll() {
            const response = await fetch(config.URL_API + `/provincias`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
    },
    domicilios_tipos: {
        async getAll(params = '') {
            const response = await fetch(config.URL_API + '/domicilios_tipos?' + params, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                },
            });
            const data = await response.json();
            return data;
        },
    },
    condiciones_iva: {
        async getAll(query = '') {
            const response = await fetch(config.URL_API + `/condicioniva?${query}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
    },
    documentos_tipos: {
        async getAll() {
            const response = await fetch(config.URL_API + `/documentotipos`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
    },
    afip: {
        async consultarCuit(cuit = '') {
            const response = await fetch(config.URL_API + `/afip/cuit/${cuit}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
    },
    coberturaGeo: {
        async get(query = '') {
            const response = await fetch(config.URL_API + `/coberturageo?${query}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async getExcluido(query = '') {
            const response = await fetch(config.URL_API + `/coberturageoexluir?${query}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
    },
    obleas:{
        async getAllCliente(id, query='') {
            const response = await fetch(config.URL_API + `/clientes/${id}/obleas?${query}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async getFranquiciasConOblea(id) {
            const response = await fetch(config.URL_API + `/obleas_compradas/${id}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
    },
    articulos:{
        async create(data) {
            const response = await fetch(config.URL_API + '/articulos', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
        },
        body: JSON.stringify(data)
    });
            const responseData = await response.json();
            return responseData;
        },
        async getAll(query = '') {
            const response = await fetch(config.URL_API + `/articulos?` + query, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
            });
            const data = await response.json();
            return data;
        },
        async getArticulos(query = '') {
            const response = await fetch(config.URL_API + `/articulos/get?` + query, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
            });
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/articulos/${id}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
    });
            const data = await response.json();
            return data;
        },
        async getByMedidas(query) {
            const response = await fetch(config.URL_API + `/articulos/bultos?` + query, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
    });
            const data = await response.json();
            return data;
        },
        async update(data){
            const response = await fetch(config.URL_API + '/articulos', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
    });
            const responseData = await response.json();
            return responseData;
        },
        async baja(id){
            const response = await fetch(config.URL_API + '/articulos/baja/'+id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
    });
            const responseData = await response.json();
            return responseData;
        },
    },
    clientes_domicilios: {
        async create(data) {
            const response = await fetch(config.URL_API + '/clientes/domicilios', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async getAll(id, query = '') {
            const response = await fetch(config.URL_API + `/clientes/${id}/domicilios?` + query, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/clientes/domicilios/${id}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async update(data) {
            const response = await fetch(config.URL_API + '/clientes/domicilios', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async delete(id) {
            const response = await fetch(config.URL_API + '/clientes/domicilios/' + id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
            });
            const responseData = await response.json();
            return responseData;
        },
    },
    clientes_autogestion_domicilios: {
        async create(data) {
            const response = await fetch(config.URL_API + '/clientes/autogestion/domicilios', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async getAll(id, query = '') {
            const response = await fetch(config.URL_API + `/clientes/autogestion/${id}/domicilios?` + query, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/clientes/autogestion/domicilios/${id}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async update(data) {
            const response = await fetch(config.URL_API + '/clientes/autogestion/domicilios', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async delete(id) {
            const response = await fetch(config.URL_API + '/clientes/autogestion/domicilios/' + id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
            });
            const responseData = await response.json();
            return responseData;
        },
    },
    comprobantes:{
        async createoblea(data) {
            const response = await fetch(config.URL_API + '/autogestion/enviar/obleas', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async ImprimirEtiqueta(id, mimeType) {
			const res = await fetch(config.URL_API + `/comprobantes/${id}/etiqueta`, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
				},
			});
			const buf = await res.arrayBuffer();
			const file = await new File([buf], id + '.pdf', { type: mimeType })
			return file;
		},
    },
    comprobantes_tipos:{
        async create(data) {
            const response = await fetch(config.URL_API + '/comprobantestipos', {
			  method: 'POST',
			  headers: {
			    'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
			  },
			  body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async getAll(query = '') {
            const response = await fetch(config.URL_API + `/comprobantestipos?` + query, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/comprobantestipos/${id}`, {
                method: 'GET',
                headers: {'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`},
			});
            const data = await response.json();
            return data;
        },
        async update(data){
            const response = await fetch(config.URL_API + '/comprobantestipos', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
			});
            const responseData = await response.json();
            return responseData;
        },
        async delete(id){
            const response = await fetch(config.URL_API + '/comprobantestipos/'+id, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
			});
            const responseData = await response.json();
            return responseData;
        },
    },
    agenda:{
        async create(data) {
            const response = await fetch(config.URL_API + '/clientes/autogestion/agenda', {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async getAll(id, query = '') {
            const response = await fetch(config.URL_API + `/clientes/autogestion/${id}/agenda?` + query, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async update(id,data) {
            const response = await fetch(config.URL_API + '/clientes/autogestion/agenda/'+id, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
    },
    clientes: {
        async getAll(query = '') {
            const response = await fetch(config.URL_API + `/clientes?${query}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async findCliente(q) {
            const response = await fetch(config.URL_API + `/clientes/find?${q}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/clientes/${id}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async getEventual(id) {
            const response = await fetch(config.URL_API + `/clientes/${id}/eventuales`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        
        async getExacto(query) {
            const response = await fetch(config.URL_API + `/clientes/exacto?${query}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async getFind(id) {
            const response = await fetch(config.URL_API + `/clientes/getfind/${id}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async create(data) {
            const response = await fetch(config.URL_API + '/clientes', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async verificar(cuit) {
            const response = await fetch(config.URL_API + `/clientes_verificar/${cuit}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async validarDatos(query = '') {
            const response = await fetch(config.URL_API + `/clientes_validacion?${query}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async enviarMail(data) {
            const response = await fetch(config.URL_API + `/clientes/mail`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async enviarWsp(data) {
            const response = await fetch(config.URL_API + `/clientes/wsp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`,
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
    },
    autogestion_clientes: {
        async getAll(query = '') {
            const response = await fetch(config.URL_API + `/autogestion_clientes?${query}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async findCliente(q) {
            const response = await fetch(config.URL_API + `/autogestion_clientes/find?${q}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async get(id) {
            const response = await fetch(config.URL_API + `/autogestion_clientes/${id}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async getExacto(query) {
            const response = await fetch(config.URL_API + `/autogestion_clientes/exacto?${query}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async getFind(id) {
            const response = await fetch(config.URL_API + `/autogestion_clientes/getfind/${id}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async create(data) {
            const response = await fetch(config.URL_API + '/autogestion_clientes', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}`
                },
                body: JSON.stringify(data)
            });
            const responseData = await response.json();
            return responseData;
        },
        async verificar(cuit) {
            const response = await fetch(config.URL_API + `/autogestion_clientes_verificar/${cuit}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
        async validarDatos(query = '') {
            const response = await fetch(config.URL_API + `/clientes_validacion?${query}`, {
                method: 'GET',
                headers: { 'AUTHORIZATION': `Bearer ${sessionStorage.getItem('token')}` },
            });
            const data = await response.json();
            return data;
        },
    },
};
